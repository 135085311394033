<template>
  <v-container class="myCardCorrection" width="100%" v-scrollbar>
    <!-- USER INFORMATION COMPONENT -->
    <div v-show="loadallData">
      <!-- COMPONENTS -->
      <v-row class="d-flex justify-start ml-3">
        <div class="mr-2">
          <!-- USER INFO COMPONENT -->
          <UserInformation
            @updateUser="GetSenderInformation"
            :OpenUSerIfo="OpenUSerIfo"
          />
        </div>
        <div class="mr-2">
          <!-- USER INFO COMPONENT -->
          <SearchConfirmationComponent :jobkinds="jobkinds" :userEmail="SenderEmail" />
        </div>

        <!-- DIALOG FOR SELECTING TEMPLATE COMPONENT -->
        <SelectDefaultTemplate
          :menu="selectDefaultMenu"
          :Kind="forTemplateSelection"
          :AllTemplates="getEmployeeTemplate"
          :customerCode="information.CustomerCode"
          @UpdateMenu="selectDefaultMenu = false"
          @GetTemplate="AssignTemplate"
        />
      </v-row>

      <!-- CORRECTION REQUEST FORM -->
      <v-form enctype="multipart/form-data">
        <v-card
          :outlined="addAttribute.outline"
          :elevation="addAttribute.elevation"
          :color="addAttribute.color"
          :class="addAttribute.classCard"
        >
          <!-- CUSTOMER INFORMATION -->
          <v-row class="mb-n3 ma-n2">
            <v-col :cols="addAttribute.forControlNumber" class="ml-2">
              <span class="text--primary"
                >Control Number<span style="color: red"> *</span>
              </span>
              <div class="d-md-flex flex-row">
                <v-text-field
                  v-model="information.CustomerCode"
                  @blur="checkCustomerName"
                  :class="addAttribute.class"
                  color="#df6464"
                  counter="12"
                  maxlength="12"
                  dense
                  outlined
                ></v-text-field>
              </div>
            </v-col>
            <v-col :cols="addAttribute.otherClass" class="ml-2">
              <span class="text--primary">Residence Name (Japanese) </span>
              <div class="d-md-flex flex-row">
                <!-- <v-text-field
                  v-model="CustomerNameJap"
                  :class="addAttribute.class"
                  :loading="loadCustomerInfo"
                  color="#df6464"
                  class="woffClass"
                  dense
                  outlined
                  disabled
                ></v-text-field> -->
                <h2 class="woffClass" id="family_name">{{CustomerNameJap}}</h2>
              </div>
            </v-col>
          </v-row>
          <v-divider v-if="window.width > 959"></v-divider>

          <v-row class="ma-1">
            <v-col class="mb-n8">
              <!-- JOB KIND  & INQUIRY KIND -->
              <v-row class="mt-n3">
                <v-col>
                  <span class="text--primary"
                    >Job Kind
                    <span style="color: red">*</span>
                  </span>
                  <v-autocomplete
                    v-model="information.JobKind"
                    :items="jobkinds"
                    @change="CheckTemplate"
                    :disabled="
                      'CustomerCode' in information
                        ? information.CustomerCode.length < 12
                        : false
                    "
                    item-text="itemValue"
                    item-value="Code"
                    color="#df6464"
                    dense
                    outlined
                    return-object
                    clearable
                  >
                  </v-autocomplete>
                </v-col>
                <v-col>
                  <span class="text--primary"
                    >Inquiry Kind <span style="color: red">*</span>
                  </span>

                  <v-autocomplete
                    v-model="information.InquiryKind"
                    :items="inquiryKind"
                    @change="CheckTemplate"
                    :disabled="
                      'CustomerCode' in information
                        ? information.CustomerCode.length < 12
                        : false
                    "
                    item-text="itemValue"
                    item-value="Code"
                    color="#df6464"
                    dense
                    outlined
                    return-object
                    clearable
                    :item-disabled="inqKindDisabled"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <!-- CATEGORY & ITEM -->
              <v-row class="mt-n6">
                <v-col>
                  <span class="text--disabled">Category Kind </span>
                  <v-combobox
                    @keyup.enter="ChangeText(categoryItemKind.Category, 'Category')"
                    @change="
                      if (!categoryItemKind.Category) delete categoryItemKind.Item;
                    "
                    v-model="categoryItemKind.Category"
                    :items="categoryKind"
                    :disabled="
                      department != 'CAD Planning 1' &&
                      department != 'CAD Planning 2' &&
                      department != 'CAD Planning 3'
                    "
                    color="#df6464"
                    dense
                    small-chips
                    outlined
                    clearable
                  >
                  </v-combobox>
                </v-col>
                <v-col>
                  <span class="text--disabled">Item Kind </span>

                  <v-combobox
                    @keyup.enter="ChangeText(categoryItemKind.Item, 'Item')"
                    v-model="categoryItemKind.Item"
                    :items="filterItemKind"
                    :disabled="
                      !categoryItemKind.Category &&
                      department != 'CAD Planning 1' &&
                      department != 'CAD Planning 2' &&
                      department != 'CAD Planning 3'
                    "
                    color="#df6464"
                    dense
                    small-chips
                    outlined
                    clearable
                  ></v-combobox>
                </v-col>
              </v-row>
            </v-col>

            <v-divider v-if="window.width > 959" vertical></v-divider>
            <!-- SENDER INFORMATION -->
            <v-col :cols="addAttribute.sender" class="mb-n8">
              <span class="text--primary">Sender </span>
              <v-text-field
                v-model="SenderName"
                color="#df6464"
                dense
                outlined
                disabled
              ></v-text-field>
              <span class="text--primary">Sender Email </span>
              <v-text-field
                v-model="SenderEmail"
                color="#df6464"
                dense
                outlined
                disabled
              ></v-text-field>
              <div></div>
            </v-col>
          </v-row>
        </v-card>
        <!-- TO && CC -->
        <v-card
          :outlined="addAttribute.outline"
          :elevation="addAttribute.elevation"
          :color="addAttribute.color"
          :class="addAttribute.classCard"
        >
          <!-- TO AND CC -->
          <v-row class="ml-2">
            <v-col class="mb-n6 mt-n3">
              <span
                class="text--primary"
                v-if="
                  department == 'CAD Planning 1' ||
                  department == 'CAD Planning 2' ||
                  department == 'CAD Planning 3'
                "
                >Email
                <v-btn
                  :disabled="To.length ? true : false"
                  class="ml-1"
                  x-small
                  @click="employeeSearchDialog = true"
                  height="12"
                  >Search</v-btn
                ></span
              >
              <span class="text--primary" v-else>Email</span>
            </v-col>
          </v-row>
          <v-row class="ma-1 mb-n7">
            <v-col cols="6">
              <div class="d-md-flex flex-row">
                <!-- SEND BUTTON COMPONENT -->
                <ToButton
                  :To="To"
                  ref="toButton"
                  :controlNumber="information.CustomerCode"
                  @passEmailAddresses="passEmailAddressesTo"
                  @passGroup="passGroup"
                  @passEmail="updateTo"
                  class="mr-1 mt-1"
                />

                <v-combobox
                  v-model="To"
                  @blur="validateManualEmail(To, 'To')"
                  @keyup.enter="validateManualEmail(To, 'To')"
                  :items="Users"
                  item-text="Email"
                  item-value="Name"
                  color="#df6464"
                  dense
                  outlined
                  multiple
                  small-chips
                  :return-object="false"
                ></v-combobox>
              </div>
            </v-col>
            <v-col cols="6">
              <div class="d-md-flex flex-row">
                <!-- SEND BUTTON COMPONENT-->
                <CcButton
                  :To="To"
                  ref="CcButton"
                  :controlNumber="information.CustomerCode"
                  @passEmailAddresses="passEmailAddressesCc"
                  @passEmail="updateCc"
                  class="mr-1 mt-1"
                />
                <v-combobox
                  v-model="Cc"
                  :items="Users"
                  item-text="Email"
                  item-value="Name"
                  color="#df6464"
                  dense
                  outlined
                  multiple
                  small-chips
                  :return-object="false"
                ></v-combobox>
              </div>
            </v-col>
          </v-row>
          <!-- DATE DUE SEND AND CANCEL-->
          <v-row class="ma-1 mt-n12">
            <!-- OTHERS -->
            <v-col :cols="addAttribute.others">
              <span class="text--primary">Send</span>
              <v-text-field
                v-model="dateToday"
                color="#df6464"
                outlined
                dense
                disabled
              ></v-text-field>
            </v-col>
            <v-col :cols="addAttribute.others" :class="addAttribute.dateToCancelClass">
              <span class="text--primary">Due</span>
              <v-menu
                content-class="elevation-0"
                v-model="menu"
                class="text-center"
                :close-oncontent-click="false"
                transition="scale-transition"
                offet-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="information.TypeOfConfirmation"
                    :disabled="dueDisabled"
                    color="#df6464"
                    v-bind="attrs"
                    v-on="on"
                    readonly
                    outlined
                    dense
                    clearable
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="information.TypeOfConfirmation"
                  :allowed-dates="allowedDates"
                  @change="dueDateTemplate(information.TypeOfConfirmation)"
                  @input="menu = false"
                  :min="dateToday"
                  color="#df6464"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <!-- SUBJECT / STOP  AND ATTACHMENT -->
          <v-row class="ma-1 mt-n13">
            <v-col>
              <span class="text--primary">Subject <span style="color: red">*</span></span>
              <v-text-field
                v-model="information.Subject"
                color="#df6464"
                outlined
                dense
              ></v-text-field
            ></v-col>
            <v-col>
              <span class="text--primary">STOP </span>
              <v-autocomplete
                v-model="stop"
                :items="stopRecord"
                :disabled="disableCategoryAndStop"
                @change="IncludeStop(stop)"
                item-value="Template"
                item-text="text"
                color="#df6464"
                outlined
                dense
                clearable
              ></v-autocomplete
            ></v-col>
            <v-col>
              <span class="text--primary">Attachment</span>
              <span
                v-if="
                  section != 'Carte Planning' && SenderEmail == 'cad-mistake@hrd-s.com'
                "
                style="font-size: 10px; color: red"
              >
                (Revision No.Format: 0100 / 0101A)</span
              >
              <span v-else style="font-size: 10px; color: red">
                (Note: File size should be less than 8.5 MB)</span
              >
              <v-file-input
                v-model="files"
                @change="handleFileUploads()"
                color="#df6464"
                outlined
                dense
                show-size
                multiple
              ></v-file-input>
            </v-col>
          </v-row>
          <!-- MESSAGE CONTENT-->
          <v-row class="ma-1 mt-n10">
            <v-col>
              <v-textarea
                v-model="content"
                @keyup="FormatText"
                label="Email Content"
                color="#df6464"
                outlined
                auto-grow
                clearable
              ></v-textarea>
            </v-col>
          </v-row>
          <!-- SEND BUTTON AND PREVIEW BUTTON-->
          <!--  @click="SendConfirmation()" -->
          <v-row class="ma-1 mt-n12">
            <v-col :cols="addAttribute.others">
              <v-btn
                @click="needFollowUpOpenDialog()"
                :disabled="sendDisabled"
                color="#00cba3"
                block
                outlined
                >Send</v-btn
              >
            </v-col>
            <v-col :cols="addAttribute.others">
              <v-btn
                :disabled="previewDisabled"
                @click="checkPreview"
                color="#f7347a"
                block
                outlined
              >
                Preview</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
      </v-form>

      <!-- WAITING DIALOG -->
      <v-dialog
        v-model="waitingDialog"
        v-if="waitingDialog"
        persistent
        max-width="200px"
        transition="fade"
      >
        <v-card class="pa-3 text-center">
          <b style="font-size: 15px; font-family: Arial, Helvetica, sans-serif">{{
            "Plase Wait..."
          }}</b>
          <v-img src="../assets/loading.gif" width="50" height="50" style="margin: auto">
          </v-img>
        </v-card>
      </v-dialog>

      <!-- PREVIEW DIALOG -->
      <v-dialog
        :persistent="
          team != 'Plan Control' && Pages != TotalPage && department == 'CAD Planning 1'
            ? true
            : false
        "
        v-model="previewDialog"
        v-if="previewDialog"
        max-width="800px"
        transition="fade"
      >
        <v-card
          v-if="pdfAttachment && team != 'Plan Control'"
          class="pa-10"
          style="background-color: #fffafa"
        >
          <v-divider class="mb-2" />
          <div v-html="previewTemplate" />
          <v-divider class="mt-2" />
          <b>Attachment:</b>
          <br />

          <v-row>
            <v-col cols="4">
              <center class="ma-1">
                <h4>Page: {{ Pages }} / {{ TotalPage }}</h4>
              </center>
            </v-col>
            <v-col cols="4">
              <v-select
                :disabled="onLoad"
                label="Zoom"
                outlined
                dense
                :items="ZoomItem"
                item-text="perct"
                item-value="val"
                v-model="zoom"
                class="mb-n5"
              />
            </v-col>
            <v-col cols="4">
              <v-tooltip left color="primary" transition="scroll-x-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    :disabled="onLoad"
                    color="primary"
                    small
                    fab
                    @click="decrePage()"
                    ><v-icon>mdi-page-previous-outline</v-icon></v-btn
                  >
                </template>
                <span>Previous Page</span>
              </v-tooltip>
              <v-tooltip right color="primary" transition="scroll-x-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    :disabled="onLoad"
                    color="primary"
                    small
                    fab
                    @click="increPage()"
                    ><v-icon>mdi-page-next-outline</v-icon></v-btn
                  >
                </template>
                <span>Next Page</span>
              </v-tooltip>
              <v-tooltip right color="primary" transition="scroll-x-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    :disabled="onLoad"
                    color="error"
                    small
                    fab
                    @click="previewDialog = false"
                    ><v-icon>mdi-close</v-icon></v-btn
                  >
                </template>
                <span>Close</span>
              </v-tooltip>
            </v-col>
          </v-row>

          <vue-pdf-embed
            class="mt-3"
            :source="pdfAttachment"
            :page="Pages"
            :width="zoom != 0 ? zoom : null"
            @loaded="PageLoad"
            @rendered="handleDocumentRender"
          />

          <v-divider class="mt-2" />
        </v-card>
        <v-card
          v-else-if="pdfAttachment && team == 'Plan Control'"
          class="pa-10"
          style="background-color: #fffafa"
        >
          <v-divider class="mb-2" />
          <div v-html="previewTemplate" />
          <v-divider class="mt-2" />
          <b>Attachment:</b>
          <br />

          <iframe style="width: 100%; height: 1000px" :src="pdfAttachment" />
          <v-divider class="mt-2" />
        </v-card>
        <v-card v-else class="pa-10" style="background-color: #fffafa">
          <v-divider class="mb-2" />
          <div v-html="previewTemplate" />
          <v-divider class="mt-2" />
        </v-card>
      </v-dialog>

      <!-- HOW TO CONFIGURE -->
      <v-dialog
        v-model="configureDialog"
        max-width="800px"
        transition="dialog-transition"
        persistent
      >
        <v-card class="pa-5">
          <v-card-title primary-title> Configure Browser Settings </v-card-title>
          <v-divider></v-divider>

          <div style="max-height: 650px" v-scrollbar>
            <strong>For Google Chrome</strong><br /><br />
            <ol>
              <li>
                In the upper part beside the url link, click the lock logo/icon.
                <v-img
                  max-height="500"
                  max-width="500"
                  src="../assets/img/howToUse/1.png"
                />
              </li>
              <li>
                After clicking the logo/icon, click "<strong>Site settings</strong>" in
                the dialog box.
                <v-img
                  max-height="500"
                  max-width="1000"
                  src="../assets/img/howToUse/2.png"
                />
              </li>
              <li>
                Under Permissions find "<strong>Insecure content</strong>" and change to
                "<strong>Allow</strong>"
                <v-img
                  max-height="500"
                  max-width="1000"
                  src="../assets/img/howToUse/3.png"
                />
              </li>
              <br />
              <li>
                After allowing go back to '<strong>HRD Inquiry</strong>' and click
                "<strong>Reload</strong>" button below.
                <v-img
                  max-height="700"
                  max-width="1000"
                  src="../assets/img/howToUse/4.png"
                />
              </li>
              <li>
                After the browser refresh, type an example
                <strong>control number</strong> and you will able to see all information.
              </li>
            </ol>
          </div>
        </v-card>
      </v-dialog>
    </div>
    <!-- FOR SKELETON LOADER -->
    <!-- v-if="!loadallData" -->
    <!-- <v-card v-if="!loadallData" class="justify-center"
      ><h3 style="color: red">
        No Template Assign! Please Coordinate to your Leader
      </h3></v-card
    > -->
    <v-skeleton-loader
      v-if="!loadallData"
      class="myCardCorrection"
      elevation="2"
      type=" article,article,article,article,list-item-two-line,list-item-two-line, actions"
    ></v-skeleton-loader>

    <!-- For employeeSearchDialog -->
    <v-dialog v-model="employeeSearchDialog" width="300" persistent>
      <v-card>
        <v-card-title
          >Employee Search
          <v-spacer />
          <v-icon @click="employeeSearchDialog = false">mdi-close</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-3 pb-0">
          <v-text-field
            label="Employee Code"
            persistent-hint
            outlined
            dense
            hide-details
            v-model="searchEmployeeCode"
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn @click="getSearchEmployeeCode()">Search</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// import Encoding  from "encoding-japanese"
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
//  import CMapReaderFactory from 'vue-pdf-embed/src/CMapReaderFactory.js'
import store from "@/store";
import Swal from "sweetalert2";
import arrayOfNeedFollowUp from "../functions/NeedToFollowUp";
import computation from "../functions/alert";
import moment from "moment";
import objects from "../functions/alert";
import shortid from "shortid";
import UserInformation from "../components/CorrectionRequestComponent/UserInformation";
import correctionRequestTable from "../functions/httpRequest/ExternalConnection/CorrectionRequestTable";
import CorrectionCategoryTable from "../functions/httpRequest/ExternalConnection/CorrectionCategoryTable";
import JobKindTable from "../functions/httpRequest/ExternalConnection/JobKindTable";
import InquiryKindTable from "../functions/httpRequest/ExternalConnection/InquiryKindTable";
import HRDSQL1 from "../functions/httpRequest/hrdappsAPI/allConnection";
import HRDSQL from "../functions/httpRequest/ExternalConnection/hrdSql";
import TemplateTable from "../functions/httpRequest/ExternalConnection/TemplateTable";
import UsersTable from "../functions/httpRequest/ExternalConnection/UsersTable";
import ConfirmationTable from "../functions/httpRequest/ExternalConnection/ConfirmationTable";
import ToButton from "../components/CorrectionRequestComponent/ToButton.vue";
import CcButton from "../components/CorrectionRequestComponent/CcButton.vue";
import EmailTemplate from "../functions/emailTemplate";
import EmailTable from "../functions/email";
import StopTable from "../functions/httpRequest/ExternalConnection/StopAssessmentTable";
import InquiryStatusTable from "../functions/httpRequest/ExternalConnection/InquiryStatusTable";
import AWSAttachment from "../functions/Attachment";
import SelectDefaultTemplate from "../components/CorrectionRequestComponent/SelectTemplate.vue";
import SearchConfirmationComponent from "../components/CorrectionRequestComponent/SearchConfirmation.vue";
import additionalEmail from "../functions/alert";
import axios from "axios";
// import GCPortalTable from "../functions/httpRequest/OtherAPI/GCPortalAPI";
// import HolidayTable from "../functions/httpRequest/ExternalConnection/HolidayTable";
// import axios from "axios";

export default {
  data() {
    return {
      get_planner: null,
      ifpersistent: true,
      TotalPage: 0,
      Pages: 1,
      ZoomItem: [
        { val: 500, perct: "50%" },
        { val: 600, perct: "60%" },
        { val: 800, perct: "80%" },
        { val: 0, perct: "100%" },
        { val: 2000, perct: "125%" },
        { val: 2500, perct: "150%" },
        { val: 2950, perct: "175%" },
        { val: 3400, perct: "200%" },
      ],
      zoom: 0,
      onLoad: false,
      dueTemp: "",
      ConstructionJapName: "",
      ConstructionRoma: "",
      disabledCode: ["009"],
      pdfAttachment: "",
      rename: [],
      getLastName: [],
      waitingDialog: false,
      menu: false,
      loadCustomerInfo: false,
      selectDefaultMenu: false,
      loadallData: false,
      previewDialog: false,
      configureDialog: false,
      ifPreview: false,
      JobKind: store.state.userinfo.Job,
      EmployeeCode: store.state.userinfo.EmployeeCode,
      EmployeeName: store.state.userinfo.FullName,
      team: store.state.userinfo.Team,
      department: store.state.userinfo.Department,
      section: store.state.userinfo.Section,
      dateToday: moment().format("YYYY-MM-DD"),
      CustomerNameEng: "",
      CustomerNameJap: "",
      content: "",
      SenderEmail: "",
      SenderName: "",
      previewTemplate: "",
      stop: "",
      CheckUserType: "",
      signature: "",
      due: "",
      categoryItemKind: {},
      information: { InquiryKind: {} },
      forTemplateSelection: {},
      senderInfo: {},
      categoryKind: [],
      itemKind: [],
      stopRecord: [],
      holidaySchedule: [],
      jobkinds: [],
      inquiryKind: [],
      template: [],
      Users: [],
      files: [],
      To: [],
      Cc: [],
      s3Keys: [],
      getEmployeeTemplate: [],
      window: {
        width: 0,
        height: 0,
      },
      employeeSearchDialog: false,
      searchEmployeeCode: "",
      disableSearchBtn: false,
      OpenUSerIfo: false,
    };
  },
  components: {
    UserInformation: UserInformation,
    ToButton: ToButton,
    CcButton: CcButton,
    SelectDefaultTemplate: SelectDefaultTemplate,
    SearchConfirmationComponent: SearchConfirmationComponent,
    VuePdfEmbed,
  },

  async created() {
    // this.chardet();
    this.information.CustomerCode = "";
    // CALCULATE WIDTH & HEIGHT
    window.addEventListener("resize", this.handleResize);
    this.handleResize();

    // GET DATA FROM BACKEND
    // this.getIchijoTemplate();
    await this.GetSenderInformation();
    await this.noTemplateTrap();
    await this.GetJobKindAndInquiryKind();
    await this.getTemplate();
    await this.getEmail();
    await this.StopAssessment();
    await this.CategoryItemKind(this.department);
    this.loadallData = true;

    if (!this.SenderEmail || !this.signature) {
      Swal.fire({
        title: "No Sender Email and Signature",
        text: 'Please fill up the details in "User Information" before you proceed',
        imageUrl:
          "https://s3.us-east-2.amazonaws.com/inquiry.hrd-s.com/img/UserInformationPNG.png",
        allowOutsideClick: false,
        imageWidth: 450,
        imageHeight: 70,
      }).then((result) => {
        if (result.isConfirmed) {
          this.OpenUSerIfo = true;
        }
      });
    }

    // // this.chardet();
    // this.information.CustomerCode = "";
    // // CALCULATE WIDTH & HEIGHT
    // window.addEventListener("resize", this.handleResize);
    // this.handleResize();

    // // GET DATA FROM BACKEND
    // // this.getIchijoTemplate();
    // await this.GetSenderInformation();
    // await this.GetJobKindAndInquiryKind();
    // await this.getTemplate();
    // await this.getEmail();
    // await this.StopAssessment();
    // await this.CategoryItemKind(this.department);
    // this.loadallData = true;

    // if (!this.SenderEmail) {
    //   Swal.fire({
    //     title: "No Sender Email and Signature",
    //     text: 'Please fill up the details in "User Information" before you proceed',
    //     imageUrl:
    //       "https://s3.us-east-2.amazonaws.com/inquiry.hrd-s.com/img/UserInformationPNG.png",
    //     imageWidth: 450,
    //     imageHeight: 70,
    //   });
    // }
  },
  computed: {
    //*******************************//
    //  FOR RESPONSIVENESS FUNCTION  //
    //*******************************//

    addAttribute() {
      const width = this.window.width;
      if (width > 959) return computation.normalForCorrection;
      return computation.mobileForCorrection;
    },

    // eslint-disable-next-line vue/return-in-computed-property
    dueDisabled() {
      if (this.SenderEmail == "cad-mistake@hrd-s.com") return true;
      else if (this.SenderEmail == "planningsupport@hrd-s.com") return true;
      else if (this.team == "Henkou and Express") return false;
    },

    // eslint-disable-next-line vue/return-in-computed-property
    inqKindDisabled() {
      return this.inquiryKind.filter((item) => {
        return {
          text: item,
          disabled: this.disabledCode.includes(item),
        };
      });
    },

    //*******************************//
    //  DISABLED BUTTON REQUIREMENTS //
    //*******************************//

    previewDisabled() {
      let code;
      if (!this.information.InquiryKind || !("Code" in this.information.InquiryKind))
        code = "";
      else code = this.information.InquiryKind.Code;

      if (code == "001" || code == "014") {
        if (
          this.stop &&
          this.To.length > 0 &&
          this.SenderEmail &&
          this.content &&
          this.files.length &&
          this.signature
        )
          return false;
      } else if (code == "005" || code == "006" || code == "020" || code == "008") {
        if (this.SenderEmail && this.content && this.signature) return false;
      } else if (
        this.To.length &&
        this.SenderEmail &&
        this.content &&
        this.files.length &&
        this.signature
      ) {
        return false;
      }
      return true;
    },
    //*************************************//
    //  DISABLED SEND BUTTON REQUIREMENTS  //
    //*************************************//

    sendDisabled() {
      let code;
      if (!this.information.InquiryKind || !("Code" in this.information.InquiryKind))
        code = "";
      else code = this.information.InquiryKind.Code;

      if (code == "001" || code == "014") {
        if (
          this.stop &&
          this.To.length > 0 &&
          this.SenderEmail &&
          this.content &&
          this.files.length &&
          this.signature &&
          this.ifPreview
        )
          return false;
      } else if (code == "005" || code == "006" || code == "020" || code == "008") {
        if (this.SenderEmail && this.content && this.signature && this.ifPreview)
          return false;
      } else if (
        this.To.length &&
        this.SenderEmail &&
        this.content &&
        this.files.length &&
        this.signature &&
        this.ifPreview
      ) {
        return false;
      }
      return true;
    },
    //*******************************//
    //  FOR FILTERING ITEM KIND      //
    //*******************************//

    filterItemKind() {
      return this.itemKind.length
        ? this.itemKind
            .filter((el) => el.Category == this.categoryItemKind.Category)
            .map((item) => item.ItemKind)
        : [];
    },

    //************************************************//
    //    DISABLED CATEGORY KIND AND STOP CONDITION   //
    //************************************************//

    disableCategoryAndStop() {
      if (this.information.InquiryKind == undefined) return true;
      else if (
        this.information.InquiryKind.Code == "001" ||
        this.information.InquiryKind.Code == "014"
      )
        return false;

      return true;
    },
  },
  watch: {
    To(newValue) {
      if (newValue.length > 0 && this.information.InquiryKind != undefined)
        this.GetDuedate();
      else this.$set(this.information, "TypeOfConfirmation", "");
    },
  },

  methods: {
    PageLoad({ numPages }) {
      this.TotalPage = numPages;
    },

    handleDocumentRender() {
      this.onLoad = false;
    },
    increPage() {
      if (this.Pages == this.TotalPage) {
        // this.ifpersistent = false;
        console.log(this.ifpersistent);
        // this.ifpersistent = false;
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 1200,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          },
        });
        Toast.fire({
          icon: "warning",
          title: "You have reached the last page",
        });
      } else {
        this.onLoad = true;

        this.Pages++;
        // this.ifpersistent = true;
      }
    },
    decrePage() {
      if (this.Pages == 1) {
        // this.ifpersistent = true;
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 1200,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          },
        });
        Toast.fire({
          icon: "warning",
          title: "You have reached the first page",
        });
      } else {
        this.onLoad = true;
        this.Pages--;
        // this.ifpersistent = true;
      }
    },

    async noTemplateTrap() {
      await correctionRequestTable.GetSpecificUser(this.EmployeeCode).then((res) => {
        const data = res.data;
        let trap = data.filter((item) => {
          if (!item.Inquiry || !item.Job || !item.Template) {
            Swal.fire({
              title: "No Template",
              text: "Please Coordinate to your Leader",
              icon: "error",
              imageWidth: 450,
              imageHeight: 70,
            });
          }
        });
        return trap;
      });
      // const intervalObj = setInterval(() => {
      //   if (!this.loadallData) {
      //     Swal.fire({
      //       title: "No Template",
      //       text: "Please Coordinate to your Leader",
      //       icon: 'error',
      //       imageWidth: 450,
      //       imageHeight: 70,
      //     });
      //   }
      // }, 1500);

      // setInterval(() => {
      //   clearInterval(intervalObj);
      // }, 1500);
    },

    getSearchEmployeeCode() {
      axios({
        method: "get",
        url: `http://10.168.72.116:9220/getSpecificEmployees/${this.searchEmployeeCode}`,
      }).then((res) => {
        let getEmailPlanner = res.data.map((rec) => {
          if (this.searchEmployeeCode == rec.EmployeeTypeCode) return rec.MailAddress;
        });

        if (!getEmailPlanner[0]) {
          alert("Invalid Employee Number");
          this.To = [];
        } else {
          this.To = getEmailPlanner;
          this.employeeSearchDialog = false;
        }
      });
    },

    needFollowUpOpenDialog() {
      let abc = `'${this.information.InquiryKind.Code}'`;
      console.log(abc);
      let test = arrayOfNeedFollowUp.filter((rec) => {
        if (
          this.department == rec.Department &&
          this.section == rec.Section &&
          abc == rec.Code
        ) {
          return rec;
        }
      });
      if (test.length == 0) {
        let needFollowUp = "1";
        this.SendConfirmation(needFollowUp);
        return false;
      }
      if (test[0].NeedFollowUp == "YES") {
        let needFollowUp = "1";
        this.SendConfirmation(needFollowUp);
      } else if (test[0].NeedFollowUp == "NO") {
        let needFollowUp1 = "0";
        this.SendConfirmation(needFollowUp1);
      } else {
        let needFollowUp = "1";
        this.SendConfirmation(needFollowUp);
      }

      // Swal.fire({
      //   icon: "question",
      //   title: "Need to follow up with the planner?",
      //   showDenyButton: true,
      //   showCancelButton: true,
      //   confirmButtonText: "Yes, Need to follow up",
      //   denyButtonText: `No, Finished this inquiry`,
      // }).then((result) => {
      //   /* Read more about isConfirmed, isDenied below */
      //   if (result.isConfirmed) {
      //     let needFollowUp = 1;
      //     this.SendConfirmation(needFollowUp);
      //   } else if (result.isDenied) {
      //     let needFollowUp = 0;
      //     this.SendConfirmation(needFollowUp);
      //   }
      // });
    },
    dueDateTemplate(val) {
      if (val) {
        this.content.split("<br>")[0].split("\n")[8] = Object.assign("返信期限" + val);
        // console.log(el.Template)
      }
      // const findStop = () =>
      //   this.stopRecord.filter((el) =>
      //     el.Template ? this.content.includes(el.Template) : null
      //   );

      // if (val) {
      //   if (val == "NONE") {
      //     if (findStop().length) assign(`${findStop()[0].Template}\n\n`, "");
      //     else assign("..\n\n", "");
      //   } else if (val == "others") {
      //     if (findStop().length) assign(`${findStop()[0].Template}\n\n`, "");
      //     else assign("..\n\n", "");
      //   } else if (!findStop().length) {
      //     assign("..", val);
      //   } else assign(findStop()[0].Template, val);
      // } else {
      //   if (!findStop().length) return false;

      //   const result = findStop()[0].Template;
      //   assign(result, "..");
      // }

      // this.FormatText();
    },

    //****************************************//
    //  VALIDATE MANUAL EMAIL INPUT BY USER   //
    //****************************************//

    async validateManualEmail(val, type) {
      if (!val.length) return; //RETURN FOR NO RECORDS

      // REMOVE ALL MISS TYPE BY USER
      this[type].forEach((email, index) => {
        if (email.length <= 2) this[type].splice(index, 1);
      });

      // GET ALL DATA TO INCLUDE
      const toInclude = val
        .filter((at) => at.includes("@"))
        .map((el) => {
          return {
            Email: el,
            Name: `${el.split("@")[0]}`,
          };
        });

      // GET ALL DUPLICATE DATA
      const toRemove = toInclude.filter((el) =>
        this.Users.some((child) => child.Name == el.Name)
      );

      // FILTER DATA TO BE INSERT AND REMOVE DUPLICATE
      const toAdd = toInclude.filter((el) =>
        toRemove.findIndex((child) => child.Email == el.Email)
      );

      // ADDING TO USERS
      for (let recipient of toAdd) this.Users.push(recipient);

      // REMOVE EMAIL ADDRESS IN NAME
      this[type] = val.map((el) => {
        if (el.includes("@")) el = el.split("@")[0];

        return el;
      });
    },

    //****************************************//
    // INCLUDE STOP IN TEMPLATE CONFIRMATION  //
    //****************************************//

    IncludeStop(val) {
      const assign = (toReplace, text) => {
        this.content = this.content.replace(toReplace, text);
      };
      const findStop = () =>
        this.stopRecord.filter((el) =>
          el.Template ? this.content.includes(el.Template) : null
        );

      if (val) {
        if (val == "NONE") {
          if (findStop().length) assign(`${findStop()[0].Template}\n\n`, "");
          else assign("..\n\n", "");
        } else if (val == "others") {
          if (findStop().length) assign(`${findStop()[0].Template}\n\n`, "");
          else assign("..\n\n", "");
        } else if (!findStop().length) {
          assign("..", val);
        } else assign(findStop()[0].Template, val);
      } else {
        if (!findStop().length) return false;

        const result = findStop()[0].Template;
        assign(result, "..");
      }

      this.FormatText();
    },

    //************************//
    //  GROUP FROM Group.vue  //
    //************************//

    passGroup(To, Cc, newval, oldval) {
      // CHECK IF USER REMOVE EMAIL
      // if(this.SenderEmail == "hac-c@hrd-s.com" && this.template.title == "Kenzai Meisai for Takku / 建材明細"){
      // //  return this.To == "k.miwa" && this.To == "m.asano";
      // console.log(this.To)
      // }

      // else

      if (newval < oldval) {
        const groupnamer = oldval.filter((el) => !newval.includes(el))[0];
        const groupToRemove = this.$refs.toButton.getGroupUncheck(groupnamer)[0];

        this.To = this.To.filter((el) => !groupToRemove.info.To.split(",").includes(el));
        if (Object.keys(groupToRemove.info).length > 2)
          this.Cc = this.Cc.filter(
            (el) => !groupToRemove.info.Cc.split(",").includes(el)
          );
      }

      // ADD USER IF NOT EXIST
      if (To.length > 0) {
        To.forEach((el) => {
          const check = this.Users.some((users) => users.Name.includes(el.Name));

          if (!check) {
            this.Users.push({ ...el });
            this.To.push(el.Name);
          } else if (!this.To.includes(el.Name)) this.To.push(el.Name);
        });
      }
      if (Cc.length > 0) {
        Cc.forEach((el) => {
          const check = this.Users.some((users) => users.Name.includes(el.Name));
          if (!check) {
            this.Users.push({ ...el });
            this.Cc.push(el.Name);
          } else if (!this.Cc.includes(el.Name)) this.Cc.push(el.Name);
        });
      }
    },

    //*******************//
    //  ADDRESSES To     //
    //*******************//

    passEmailAddressesTo(arr, newVal, oldVal) {
      // CHECK IF USER REMOVE EMAIL

      if (newVal < oldVal) {
        const EmailAddress = oldVal.filter((el) => !newVal.includes(el))[0];
        const name = this.Users.filter((el) => el.Email == EmailAddress)[0].Name;
        this.To = this.To.filter((el) => el != name);
        return;
      }

      // ADD USER IF NOT EXIST
      if (arr.length > 0) {
        arr.forEach((el) => {
          if (!this.Users.some((users) => users.Name.includes(el.Name))) {
            this.Users.push({ ...el });
            this.To.push(el.Name);
          } else if (!this.To.includes(el.Name)) this.To.push(el.Name);
        });
      }
    },

    //*******************//
    //  ADDRESSES CC     //
    //*******************//

    passEmailAddressesCc(arr, newVal, oldVal) {
      // CHECK IF USER REMOVE EMAIL
      if (newVal < oldVal) {
        const EmailAddress = oldVal.filter((el) => !newVal.includes(el))[0];
        const name = this.Users.filter((el) => el.Email == EmailAddress)[0].Name;
        this.Cc = this.Cc.filter((el) => el != name);
        return;
      }
      // ADD USER IF NOT EXIST
      if (arr.length > 0) {
        arr.forEach((el) => {
          if (!this.Users.some((users) => users.Name.includes(el.Name))) {
            this.Users.push({ ...el });
            this.Cc.push(el.Name);
          } else if (!this.Cc.includes(el.Name)) this.Cc.push(el.Name);
        });
      }
    },

    //***********************************************//
    //  UPDATING EMAIL INFO FROM ToButton COMPONENT  //
    //***********************************************//

    updateTo(arr, newVal, oldVal) {
      // CHECK IF USER REMOVE EMAIL
      if (newVal < oldVal) {
        const EmailAddress = oldVal.filter((el) => !newVal.includes(el))[0];
        const name = this.Users.filter((el) => el.Email == EmailAddress)[0].Name;

        this.To = this.To.filter((el) => el != name);
        return;
      }

      // ADD USER IF NOT EXIST
      if (arr.length) {
        arr.forEach((el) => {
          const checkForUser = this.Users.some((users) => users.Name.includes(el.Name));
          if (!checkForUser) {
            this.Users.push({ ...el });
            this.To.push(el.Name);
          } else if (!this.To.includes(el.Name)) this.To.push(el.Name);
        });
      }
    },

    //***********************************************//
    //  UPDATING EMAIL INFO FROM CcButton COMPONENT  //1
    //***********************************************//

    updateCc(arr, newVal, oldVal) {
      // CHECK IF USER REMOVE EMAIL
      if (newVal < oldVal) {
        const EmailAddress = oldVal.filter((el) => !newVal.includes(el))[0];
        const name = this.Users.filter((el) => el.Email == EmailAddress)[0].Name;
        this.Cc = this.Cc.filter((el) => el != name);
        return;
      }

      // ADD USER IF NOT EXIST
      if (arr.length > 0) {
        arr.forEach((el) => {
          if (!this.Users.some((users) => users.Name.includes(el.Name))) {
            this.Users.push({ ...el });
            this.Cc.push(el.Name);
          } else if (!this.Cc.includes(el.Name)) this.Cc.push(el.Name);
        });

        // this.information.To = arr;
      }
    },

    async checkCustomerName() {
      if (this.information.CustomerCode) {
        this.loadCustomerInfo = true;
        if (!this.information.CustomerCode) {
          this.CustomerNameEng = "";
          this.CustomerNameJap = "";
          return;
        }
        await HRDSQL1.ResidenceName(this.information.CustomerCode)
          .then(async (res) => {
            this.CustomerNameJap = res[0].ModifiedConstructionName
              ? `${res[0].ModifiedConstructionName} 様邸` 
              : "N/A";
            this.loadCustomerInfo = false;
            this.ConstructionRoma = res[0].ModifiedConstructionNameRoma;
            this.ConstructionJapName = res[0].ModifiedConstructionNameRoma;

            if (this.To) {
              this.disableSearchBtn = true;
            } else {
              this.disableSearchBtn = false;
            }

            let tmpFirstName = res[0].ModifiedConstructionNameRoma.split(" ")[0];
            console.log(tmpFirstName);

            let response = await axios.post("http://10.11.1.59:8010/api/hrdtranslate", {
              text: tmpFirstName.toString(),
              target: "ja",
              key: "h78r78d",
              app: "iir",
            }); // 辻
            let toSend = res.map((item) => {
              let japFirstNameLastName = item.ModifiedConstructionName;
              if (japFirstNameLastName[0] == "") {
                let fullName = [response.data[0], japFirstNameLastName[1]];
                item.ModifiedConstructionName = fullName.join("　");
              }

              return item;
            });

              this.getLastName = `${toSend[0].ModifiedConstructionName} 様邸`;
              // console.log(this.getLastName,"1433")
          })
          .catch((err) => {
            console.log(err);
            this.configureDialog = true;
          });
      }
    },

    //******************************//
    //  SENDING EMAIL CONFIRMATION  //
    //******************************//

    async SendConfirmation(needFollowUp) {
      // CHECK REQUIRED
      let customerCodee = this.information.CustomerCode;
      if (this.files == customerCodee) {
        this.$toast.error(
          "Control Number and the attachment are not equal",
          objects.alert
        );
      }

      if (
        this.information.InquiryKind.Code == "001" ||
        this.information.InquiryKind.Code == "014"
          ? !this.stop
          : "" ||
            !this.information.CustomerCode ||
            !this.information.JobKind ||
            !this.information.InquiryKind ||
            !this.To ||
            !this.information.Subject ||
            !this.content ||
            !this.SenderEmail
      ) {
        return this.$toast.error("Fill-up all required fields", objects.alert);
      }

      // IF NO EMAIL FROM USER
      if (!this.SenderEmail)
        return this.$toast.error("Fill-up User Information Email", objects.alert);

      this.waitingDialog = true;

      // VALIDATE DATA TO BE SEND TO BACKEND
      let promise = [];
      var additionalText = "";
      if (
        this.information.InquiryKind.Code == "022" ||
        this.information.InquiryKind.Code == "021"
      ) {
        additionalText = "";
      } else {
        additionalText = `<br><br>宜しくお願い致します。<br><br>${this.signature}`;
      }
      this.information.ConfirmationID = shortid.generate();
      this.information.MessageContent = `${this.information.MessageContent}${additionalText}`;
      let inquiryStatInfo = { ...this.information };
      this.information.Status =
        needFollowUp === "1" || !needFollowUp ? "Not Started" : "Finished";
      this.information.SenderID = this.EmployeeCode;
      this.information.From = this.EmployeeName;
      this.information.Department = this.department;
      this.information.To = this.To.join();
      this.information.Cc = this.Cc.join();
      this.information.SenderEmail = store.state.userinfo.Email;
      this.information.ToEmail = this.Users.filter((el) => {
        return this.information.To.split(",").includes(el.Name);
      })
        .map((el) => el.Email)
        .join(",");
      this.information.CcEmail = this.Users.filter((el) => {
        return this.information.Cc.split(",").includes(el.Name);
      })
        .map((el) => el.Email)
        .join(",");
      // console.log(this.information);

      // FOR REPLY
      const ToEmailReply = this.Users.filter((el) => {
        return this.To.includes(el.Name);
      }).map((el) => el.Email);
      console.log(ToEmailReply);
      const reply = {
        ConfirmationID: this.information.ConfirmationID,
        SenderID: this.information.SenderID,
        SenderName: this.SenderName,
        ToEmail: ToEmailReply.length ? JSON.stringify(ToEmailReply) : ToEmailReply,
        SenderEmail: store.state.userinfo.Email,
        To: this.information.To,
        CustomerCode: this.information.CustomerCode,
        Subject: this.information.Subject,
        MessageContent: this.information.MessageContent,
        Path: null,
        Attachment: null,
      };

      // ATTACHMENT
      if (this.files.length) {
        const date = moment().format("YYYY/MM/DD");
        reply.Path = `Correction/${date}`;

        await AWSAttachment.upload(
          this.information.InquiryKind.Code,
          "Correction", //Folder path
          this.files,
          (fileName) => (reply.Attachment = fileName), //callback for saving path and name to database
          async (keyName) => {
            //callback after sending all attachment in s3
            this.s3Keys = keyName.map((el) => `${reply.Path}/${el}`);
            await sendingEmail();
            await ClearRecord();
          }
        );
      }
      //Detect the data if Inquirykin is with follow up or finished
      // axios.get().then;

      // FOR INQUIRY STATUS SAVING
      inquiryStatInfo.Attachment =
        "Attachment" in reply && reply.Attachment ? reply.Attachment.join() : null;

      inquiryStatInfo.Path = reply.Path;
      inquiryStatInfo.NeedFollowUp = needFollowUp;
      // let stringStatus = "";

      inquiryStatInfo.Status =
        needFollowUp === "1" || !needFollowUp ? "Not yet reply" : "Finished";
      inquiryStatInfo.FinishedDate =
        needFollowUp === "1" || !needFollowUp ? null : moment().format("YYYY-MM-DD");
      promise.push(InquiryStatusTable.Post(this.InquiryStatus(inquiryStatInfo)));

      // GET JAPANESE NAME
      const japaneseName = this.information.InquiryKind.JapaneseName;
      // FOR TEMPLATE
      const inquiryCode =
        this.information.InquiryKind.Code == "001" ||
        this.information.InquiryKind.Code == "006" ||
        this.information.InquiryKind.Code == "007" ||
        this.information.InquiryKind.Code == "010" ||
        this.information.InquiryKind.Code == "014" ||
        this.information.InquiryKind.Code == "005"
          ? true
          : false;
      // DELETE OBJECT
      delete this.information.InquiryKind;
      delete this.information.JobKind;
      delete this.information.CustomerCode;

      // CHECK EMAIL TO SEND
      const ToEmail = this.Users.filter((el) => {
        return this.information.To.split(",").includes(el.Name);
      })
        .map((el) => el.Email)
        .join(",");
      // CHECK CC TO SEND 11111
      let ToCc = this.Users.filter((el) =>
        this.information.Cc.split(",").includes(el.Name)
      ).map((el) => el.Email);

      if (
        this.SenderEmail.toLowerCase() == "cad-mistake@hrd-s.com" ||
        this.SenderEmail.toLowerCase() == "cad@hrd-s.com" ||
        this.SenderEmail == "sp-technical@hrd-s.com"
      ) {
        //   // console.log(ToCc.length,"1138")
        if (ToCc.length == 0) {
          ToCc = [];
        }
        //   // ToCc.push("sd2leaders@hrd-s.com");
        //   // ToCc.push("hrdsystemmanagement@gmail.com");
        //   // ToCc = ToCc.join(",");
      } else {
        ToCc.push(this.SenderEmail);
        // ToCc.push("hrdsystemmanagement@gmail.com");
        ToCc = ToCc.join(",");
        // console.log(ToCc.push);
      }

      // NAME OF RECEIVER 123
      const receiver = this.Users.filter((el) => {
        return this.information.To.split(",").includes(el.Name);
      })
        .map((el) => el.Name)
        .join(",");
      //  console.log(ToCc,"1152")
      // GET CONFIRMATION LENGTH AND TEMPLATE
      const sendingEmail = async () => {
        await ConfirmationTable.GetCount().then(async (res) => {
          const data = {
            // CODE
            dataLength: res.data,
            Subject: reply.Subject,
            SenderEmail: store.state.userinfo.Email,
            CustomerCode: reply.CustomerCode,
            Content: reply.MessageContent,
            Receiver: receiver,
            Due: this.information.TypeOfConfirmation,
            ConfirmationID: reply.ConfirmationID,
            Stop: this.stop,
            inquiryCode: inquiryCode,
            customerName: this.CustomerNameJap,
            isPlanner: this.get_planner,
          };
          const cadTemp = {
            // CODE
            dataLength: res.data,
            Subject: reply.Subject,
            CustomerCode: reply.CustomerCode,
            Content: reply.MessageContent,
            Receiver: receiver,
            Due: this.information.TypeOfConfirmation,
            ConfirmationID: reply.ConfirmationID,
            Stop: this.stop,
            inquiryCode: inquiryCode,
            customerName: this.CustomerNameJap,
            isPlanner: this.get_planner,

            // sendEmail:this.SenderEmail
          };
          // VARIABLE FOR SUBJECT
          const due = this.information.TypeOfConfirmation;

          const subject = `【${japaneseName}】${
            reply.Subject.split("/")[reply.Subject.split("/").length - 1]
          }《${reply.CustomerCode}》(${this.getLastName}) ${
            due ? `返信期限${due.split("-")[1]}月${due.split("-")[2]}日` : ""
          } `;

          // SEND EMAIL
          if (
            this.SenderEmail == "cad-mistake@hrd-s.com" ||
            this.SenderEmail == "cad@hrd-s.com"
          ) {
            await EmailTable.sendCorrection(
              this.information.ConfirmationID,
              this.information.Status,
              this.information.Department,
              this.information.SenderID,
              due,
              `${this.SenderName} <${this.SenderEmail}>`, //SENDER EMAIL AND NAME
              // this.SenderEmail,
              "no-reply@hrd-ihs.com",
              ToEmail.split(","), //RECIPIENT
              ToCc.length >= 1 ? ToCc : [], // IF WITH CC

              // ToCc,
              EmailTemplate.correctionRequestTemplate(cadTemp, this.SenderName), //EMAIL TEMPLATE
              reply.CustomerCode, //CUSTOMER CODE
              subject,
              // sjisArray, //subject,// SUBJECT OF CONFIRMATION
              // unicodeArray2,
              this.s3Keys //S3KEY FOR SENDING EMAIL
            );
            // console.log(EmailTemplate.TemplateCorrectionRequestEmail(cadTemp));
          } else if (this.SenderEmail == "sp-technical@hrd-s.com") {
            console.log(ToCc);
            await EmailTable.sendCorrection(
              this.information.ConfirmationID,
              this.information.Status,
              this.information.Department,
              this.information.SenderID,
              due,
              `${this.SenderName} <${this.SenderEmail}>`, //SENDER EMAIL AND NAME
              // this.SenderEmail,
              "no-reply@hrd-ihs.com",
              ToEmail.split(","), //RECIPIENT
              ToCc.length >= 1 ? ToCc : [], // IF WITH CC

              // ToCc,
              EmailTemplate.correctionRequestTemplate(data, this.SenderName), //EMAIL TEMPLATE
              reply.CustomerCode, //CUSTOMER CODE
              subject,
              // sjisArray, //subject,// SUBJECT OF CONFIRMATION
              // unicodeArray2,
              this.s3Keys //S3KEY FOR SENDING EMAIL
            );
          } else {
            // return;
            console.log(ToCc, "1494");
            await EmailTable.sendCorrection(
              this.information.ConfirmationID,
              this.information.Status,
              this.information.Department,
              this.information.SenderID,
              due,
              `${this.SenderName} <${this.SenderEmail}>`,
              // this.SenderEmail, //SENDER EMAIL AND NAME
              "no-reply@hrd-ihs.com",
              ToEmail.split(","), //RECIPIENT
              ToCc.split(",") || null, // IF WITH CC
              // EmailTemplate.TemplateCorrectionRequestWithSystemLink(data), //FIRST OPTION
              //SECOND OPTION
              // ,
              //   ToCc,
              //   subject.split(" ").join("%20"),
              //   store.state.userinfo.Email,
              //   EmailTemplate.TemplateCorrectionRequestReply(
              //     data,
              //     ToCc.split(",").join(","),
              //     subject.split(" ").join("%20"),
              //     this.SenderEmail
              //   )
              // .split(" ")
              // .join("%20")
              // .split("<br>")
              // .join("%0D%0A")
              // .split("<br/>")
              // .join("%0D%0A")
              EmailTemplate.correctionRequestTemplate(data, this.SenderName), //EMAIL TEMPLATE
              reply.CustomerCode, //CUSTOMER CODE
              subject,
              this.s3Keys //S3KEY FOR SENDING EMAIL
            );
          }
        });
      };

      //CREATE PROMISE FOR INSERT RECORD
      promise.push(
        ConfirmationTable.PostReply(reply).then(() => {
          let store = {
            ...reply,
          };
          // let allRecepients = [...reply.ToEmail]
          delete store.ToEmail;
          delete store.CcEmail;
          delete store.Cc;
          delete store.TypeOfConfirmation;
          delete store.Status;
          delete store.RuleBookNumber;
          delete store.InquiryType;
          delete store.InquiryItem;
          delete store.Department;
          // for (let email of allRecepients) {
          //   // console.log({
          //   //   ...store,
          //   //   email: email,
          //   // });
          //   // this.$socket.emit("newUpdate", {
          //   //   ...store,
          //   //   email: email,
          //   //   hrdInquiryStatus: "notificationAlert",
          //   // });
          // }
        }),
        ConfirmationTable.PostCorrection(this.information)
      );

      //CALLBACK FROM PROMISE SENDING
      Promise.all(promise)
        .then(async () => {
          if (!reply.Attachment) {
            await sendingEmail();
            await ClearRecord();
          }
        })
        .catch(() => ClearRecord(true));

      const ClearRecord = async (err = false) => {
        if (err) {
          console.log(err, "1283");
          this.$toast.warning("Something went wrong", objects.alert);
        } else {
          this.$toast.warning("Uploading please wait", objects.alert);
          // this.CheckAndSaveKind(this.categoryItemKind);
        }

        this.To = [];
        this.Cc = [];
        this.s3Keys = [];
        this.files = [];
        this.categoryItemKind = {};
        //DITO CODE
        if (
          this.department == "CAD Planning 1" ||
          this.department == "CAD Planning 2" ||
          this.department == "CAD Planning 3"
        ) {
          this.information = { CustomerCode: "" };
          this.information.JobKind = this.jobkinds[0];
        } else {
          this.information = { CustomerCode: "" };
        }
        this.CustomerNameEng = "";
        this.CustomerNameJap = "";
        this.due = "";
        this.content = "";
        this.stop = "";
        this.$refs["toButton"].ClearData();
        this.$refs["CcButton"].ClearData();
        this.waitingDialog = false;
        this.pdfAttachment = "";
        this.zoom = 0;
        this.TotalPage = 0;
        this.Pages = 1;

        this.ifPreview = !this.ifPreview;
      };
    },
    //******************//
    //  INQUIRY STATUS  //
    //******************//

    InquiryStatus({ ...val }) {
      const HandleStaff = this.Users.filter((el) => this.To.includes(el.Name))
        .map((el) => el.Name)
        .join(",");

      const SubHandleStaff = this.Users.filter((el) => this.Cc.includes(el.Name))
        .map((el) => el.Name)
        .join(",");

      const ToEmail = this.Users.filter((el) => {
        return this.To.includes(el.Name);
      }).map((el) => el.Email);

      const ToCc = this.Users.filter((el) => {
        return this.Cc.includes(el.Name);
      }).map((el) => el.Email);

      val.ContentOfInquiry = val.MessageContent;
      val.DueDate = val.TypeOfConfirmation;

      delete val.TypeOfConfirmation;
      delete val.MessageContent;

      const data = {
        ...val,
        JobKind: val.JobKind.EnglishName,
        InquiryKind: val.InquiryKind.JapaneseName,
        Type: 0,
        ReceivedDate: moment().format("YYYY-MM-DD"),
        ReceivedTime: moment().format("HH:mm:ss"),
        SenderStatus: "HRD",
        SenderName: this.SenderName,
        Department: this.department,
        HandleStaff: HandleStaff,
        CategoryKind: this.categoryItemKind.Category,
        ItemKind: this.categoryItemKind.Item,
        SubHandleEmail: JSON.stringify(ToCc),
        HandleEmail: JSON.stringify(ToEmail),
        SubHandleStaff: SubHandleStaff,
      };

      return data;
    },

    //******************************//
    //    MANAGE FILE UPLOADING     //
    //******************************//

    handleFileUploads() {
      if (!this.SenderEmail) {
        Swal.fire({
          title: "No Sender Email and Signature",
          text: 'Please fill up the details in "User Information" before you proceed',
          imageUrl:
            "https://s3.us-east-2.amazonaws.com/inquiry.hrd-s.com/img/UserInformationPNG.png",
          imageWidth: 450,
          imageHeight: 70,
        });
      }
      if (
        store.state.userinfo.Section == "Plan Administration" ||
        store.state.userinfo.Section == "Waku Planning 2" ||
        store.state.userinfo.Email == "cad-mistake@hrd-s.com"
      ) {
        // let fileDate = moment().format("YYYYMMDDhhmmss");
        let forRenanme = this.files[0].name.split(".");

        let forTrapping = forRenanme[0].split("-");

        let combineTrapping = forTrapping[0] + "-" + forTrapping[1];
        console.log(combineTrapping);

        // console.log(forRenanme);
        // if(customerName == file)
        let customerCodee = this.information.CustomerCode;

        if (combineTrapping == customerCodee) {
          let fileSize = 0;
          for (let x in this.files) {
            fileSize += this.files[x].size;
            if (this.files[x].name.split(".")[1].toUpperCase() == "PDF") {
              let previewFile = URL.createObjectURL(this.files[x]);
              // window.open(previewFile);
              this.pdfAttachment = previewFile;
              console.log(this.pdfAttachment);
            }
          }
          //10000000 = 10mb size.
          if (fileSize > 8500000) {
            this.files = [];
            this.$toast.error("File must be less than 8.5MB", objects.alert);
          }
          // this.reply.Attachment = forRenanme[0] + '-' + fileDate + "-Z--HRD PROCESS."+forRenanme[1]
          // this.files = renames
          // console.log(this.reply.Attachment);
          return this.$toast.success("Success", objects.alert);
        } else if (this.files[0].name.match("TH")[0]) {
          let fileSize = 0;
          for (let x in this.files) {
            fileSize += this.files[x].size;
            if (this.files[x].name.split(".")[1].toUpperCase() == "PDF") {
              let previewFile = URL.createObjectURL(this.files[x]);
              // window.open(previewFile);
              this.pdfAttachment = previewFile;
              console.log(this.pdfAttachment);
            }
          }
          //10000000 = 10mb size.
          if (fileSize > 8500000) {
            this.files = [];
            this.$toast.error("File must be less than 8.5MB", objects.alert);
          }
          return this.$toast.success("Success", objects.alert);
        } else {
          this.$toast.error(
            "Control Number and the attachment are not equal",
            objects.alert
          );
          this.files = [];
        }
      }
      // console.log()
      // console.log(this.information.CustomerCode)
      else {
        let fileSize = 0;
        for (let x in this.files) {
          fileSize += this.files[x].size;
          if (this.files[x].name.split(".")[1].toUpperCase() == "PDF") {
            let previewFile = URL.createObjectURL(this.files[x]);
            // window.open(previewFile);
            this.pdfAttachment = previewFile;
            console.log(this.pdfAttachment);
          }
        }
        //10000000 = 10mb size.
        if (fileSize > 8500000) {
          this.files = [];
          this.$toast.error("File must be less than 8.5MB", objects.alert);
        }
      }
    },

    //**********************************//
    //  GET IF USER HAVE SAVE TEMPLATE  //
    //**********************************//
    CheckTemplate() {
      this.content = "";
      this.stop = "";
      this.due = "";
      this.To = [];
      this.Cc = [];
      this.categoryItemKind = {};
      this.$refs["toButton"].ClearData();
      this.$refs["CcButton"].ClearData();
      this.information.Subject = "";
      // console.log(this.information.JobKind, "1757");
      // CHECK FOR !NULL JOBKIND & INQUIRY
      if (this.information.JobKind && this.information.InquiryKind) {
        // FOR TENJIJOU PLAN

        // FILTER SELECTED TEMPLATE
        const template = this.getEmployeeTemplate.filter((el) => {
          return (
            el.InquiryKindID == this.information.InquiryKind.Code &&
            el.JobKindID == this.information.JobKind.Code
          );
        });

        // RETURN IF NO TEMPLATE
        if (template.length === 0) return;
        if (template.length) {
          // GET ALL TEMPLATE FOR SPECIFIC USER
          // PASS OBJECT TO PROPS
          this.forTemplateSelection = {
            JobKind: this.information.JobKind.Code,
            InquiryKind: this.information.InquiryKind.Code,
          };

          this.selectDefaultMenu = true;
        }
      }
    },

    //**********************************//
    //    ASSIGN SELECTED TEMPLATE      //
    //**********************************//

    AssignTemplate(val) {
      // console.log(val, "1966");
      let get_plannerArr = JSON.parse(val.To);
      this.get_planner = get_plannerArr.includes("Planner");
      // console.log(this.get_planner);

      // console.log(get_planner, "1968");
      this.content = val.Template.split("<br>").join("\n");
      this.information.MessageContent = val.Template;
      this.information.Subject = val.Title;

      // FOR PLANNER OR SALESMAN
      const Customerinfo = (Email, type) => this.$refs[type].CheckForCodeInfo([Email]);

      // FOR MANUAL EMAIL
      const ManualInfo = (Email, type) => {
        const check = this.Users.some((el) => el.Email == Email);

        const existingEmail = this.Users.filter((email) => email.Email == Email);

        if (!check) {
          this.Users.push({
            Email: Email,
            Name: Email.split("@")[0],
          });

          this[type].push(Email.split("@")[0]);
        } else this[type].push(existingEmail[0].Name);
      };

      if (val.To) {
        const To = JSON.parse(val.To);

        for (let x in To) {
          if (To[x].includes("@")) ManualInfo(To[x], "To");
          else Customerinfo(To[x], "toButton");

          if (To[x] === "Planner" && To[x] === "Salesman") this.CheckUserType = To[x];
        }
      }

      if (val.Cc) {
        const Cc = JSON.parse(val.Cc);

        for (let x in Cc) {
          if (Cc[x].includes("@")) ManualInfo(Cc[x], "Cc");
          else Customerinfo(Cc[x], "CcButton");
        }
      }
    },

    //*******************//
    // GET TEMPLATE API  //
    //*******************//

    async getTemplate() {
      const template = this.senderInfo.Template.length
        ? JSON.parse(this.senderInfo.Template).join("','")
        : "";

      await TemplateTable.GetUser({ template: template }).then((res) => {
        // console.log(res.data);
        this.getEmployeeTemplate = res.data;
      });
    },

    //***********************************************//
    // GET ALL USER FROM GC PORTAL AND RULEBOOK API  //
    //***********************************************//

    async getEmail() {
      // // GC PORTAL ALL USER
      // await GCPortalTable.getAll().then(async (res) => {
      //   this.Users = res.data.map((el) => {
      //     return {
      //       Email: el.MailAddress,
      //       Name: el.Name,
      //     };
      //   });
      //   // RULEBOOK ALL USER
      //   await GCPortalTable.GetAllRulebook().then((res) => {
      //     this.Users = res.data.map((el) => {
      //       return {
      //         Email: el.MailAddress,
      //         Name: el.Name,
      //       };
      //     });
      //   });
      // EXTERNAL ALL USER
      await UsersTable.GetAllUser().then((res) => {
        for (let x in res.data)
          this.Users.unshift({
            Email: res.data[x].Email,
            Name: res.data[x].Fullname,
          });
        // REMOVE NO EMAIL
        let FilterUser = this.Users.filter((el) => el.Email).filter(
          (el, index, self) => index === self.findIndex((find) => find.Email === el.Email)
        );

        for (let emailObj of additionalEmail.additionalEmail()) FilterUser.push(emailObj);

        this.Users = FilterUser.sort((a, b) => {
          if (a.Email < b.Email) return -1;
          if (a.Email > b.Email) return 1;

          return 0;
        });
        // this.loadallData = true;
      });
      // });
    },

    //****************************//
    // GET CUSTOMER NAME FROM CI  //
    //****************************//

    getIchijoTemplate() {
      if (store.state.userinfo.UserType == "Ichijo") {
        this.loadallData = true;
      }
    },

    //*************************************//
    // CHECK FOR CORRECTION REQUEST USERS  //
    //*************************************//

    async GetSenderInformation() {
      await correctionRequestTable.GetSpecificUser(this.EmployeeCode).then((res) => {
        const data = res.data;
        this.senderInfo = { ...data[0] };
        if (!this.senderInfo.Template) this.senderInfo.Template = [];
        if (!this.senderInfo.Job) this.senderInfo.Job = [];
        if (!this.senderInfo.Inquiry) this.senderInfo.Inquiry = [];
        // CHECK FOR VALUE
        this.SenderName = !data[0].EmployeeName ? "" : res.data[0].EmployeeName;
        this.SenderEmail = !data[0].EmployeeName ? "" : res.data[0].Email;
        this.signature = !data[0].Signature ? "" : res.data[0].Signature;
      });
    },
    //***************************************//
    // HANDLE RESPONSIVENESS WHEN RESIZING   //
    //***************************************//

    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },

    //****************************//
    //     MANAGE TEXT FORMAT     //
    //****************************//

    FormatText() {
      this.information.MessageContent = this.content.split("\n").join("<br>");
    },

    //****************************//
    //       EMAIL PREVIEW        //
    //****************************//

    async checkPreview() {
      // if(!this.To){
      //   this.configureDialog = true
      //   return false
      // }

      this.ifPreview = true;
      this.due = this.information.TypeOfConfirmation;

      await this.validateManualEmail(this.To, "To");
      await this.validateManualEmail(this.Cc, "Cc");

      await ConfirmationTable.GetCount().then((res) => {
        // GET ALL EMAIL
        const toReceiver = this.To.filter((el) => el != "Salesman")
          .filter((el) => el != "Planner")
          .toString();
        // FILTER EMAIL
        const ToEmail = this.Users.filter((el) => {
          return toReceiver.split(",").includes(el.Name);
        })
          .map((el) => el.Email)
          .join(",");
        // GET RECEIVER
        const receiver = this.Users.filter((el) => {
          return toReceiver.split(",").includes(el.Name);
        })
          .map((el) => el.Name)
          .join(", ");
        // GET INQUIRY CODE
        const inquiryCode =
          this.information.InquiryKind.Code == "001" ||
          this.information.InquiryKind.Code == "006" ||
          this.information.InquiryKind.Code == "007" ||
          this.information.InquiryKind.Code == "010" ||
          this.information.InquiryKind.Code == "014" ||
          this.information.InquiryKind.Code == "005"
            ? true
            : false;
        // INFORMATION PREVIEW

        const reply = {
          ConfirmationID: this.information.ConfirmationID,
          SenderID: this.information.SenderID,
          SenderName: this.SenderName,
          CustomerCode: this.information.CustomerCode,
          Subject: this.information.Subject,
          MessageContent: this.information.MessageContent,
        };

        const data = {
          dataLength: res.data,
          Subject: reply.Subject,
          CustomerCode: reply.CustomerCode,
          Content: reply.MessageContent,
          Receiver: receiver,
          Due: this.information.TypeOfConfirmation,
          ConfirmationID: reply.ConfirmationID,
          Stop: this.stop,
          inquiryCode: inquiryCode,
          inquiryKind: this.information.InquiryKind.Code,
          customerName: this.CustomerNameJap,
          signature: this.signature,
        };

        const information = {
          SenderName: this.SenderName,
          SenderEmail: this.SenderEmail,
          ReceiverName: receiver,
          ReceiverEmail: ToEmail,
        };
        const cadTemp = {
          dataLength: res.data,
          Subject: reply.Subject,
          CustomerCode: reply.CustomerCode,
          Content: reply.MessageContent,
          Receiver: receiver,
          Due: this.information.TypeOfConfirmation,
          ConfirmationID: reply.ConfirmationID,
          Stop: this.stop,
          inquiryCode: inquiryCode,
          customerName: this.CustomerNameJap,
          signature: this.signature,
          sendEmail: this.SenderEmail,
        };
        // GET TEMPLATE

        if (
          this.SenderEmail == "cad-mistake@hrd-s.com" ||
          this.SenderEmail == "cad@hrd-s.com"
        ) {
          // console.log(
          //   EmailTemplate.TemplateCorrectionRequestEmailContent(
          //     information,
          //     cadTemp
          //   )
          // );
          this.previewTemplate = EmailTemplate.PreviewTemplate(
            information,
            EmailTemplate.TemplateCorrectionRequestEmailContent(cadTemp)
          );
          // this.previewTemplate = template1;
        } else {
          const template = EmailTemplate.PreviewTemplate(
            information,
            EmailTemplate.TemplateCorrectionRequest(data)
          );
          this.previewTemplate = template;
        }

        // this.previewTemplate = this.template1;
        this.previewDialog = true;

        // var printW = window.open("");
        // printW.document.write(template);
        // printW.document.close();
        // printW.focus();
        // printW.print();
        // printW.close();
      });
    },

    //************************************************//
    //     GET AVAILABLE JOBKIND AND INQUIRY KIND     //
    //************************************************//

    async GetJobKindAndInquiryKind() {
      await JobKindTable.Get().then((res) => {
        for (let x in res.data)
          res.data[
            x
          ].itemValue = `${res.data[x].Code} / ${res.data[x].EnglishName} ( ${res.data[x].JapaneseName} )`;

        this.jobkinds = res.data.filter((el) =>
          JSON.parse(this.senderInfo.Job).includes(el.Code)
        );
        if (
          this.department == "CAD Planning 1" ||
          this.department == "CAD Planning 2" ||
          this.department == "CAD Planning 3"
        ) {
          this.information.JobKind = this.jobkinds[0];
        }
      });
      await InquiryKindTable.Get().then((res) => {
        for (let x in res.data)
          res.data[
            x
          ].itemValue = `${res.data[x].Code} / ${res.data[x].EnglishName} ( ${res.data[x].JapaneseName} )`;
        this.inquiryKind = res.data.filter((el) =>
          JSON.parse(this.senderInfo.Inquiry).includes(el.Code)
        );
      });
    },

    //********************************//
    //     GET CATEGORY/ITEM kIND     //
    //********************************//

    async CategoryItemKind(department) {
      await CorrectionCategoryTable.GetCategory(department).then(
        (res) =>
          (this.categoryKind = res.data.length ? res.data.map((el) => el.Category) : [])
      );
      await CorrectionCategoryTable.GetItemKind(department).then(
        (res) => (this.itemKind = !Array.isArray(res.data) ? [] : res.data)
      );
    },

    //********************************************//
    //    INSERT IF EMPTY CATEGORY/ITEM KIND      //
    //********************************************//

    CheckAndSaveKind(kind = {}) {
      if (Object.keys(kind).length === 2) {
        let promise = [];
        // CHECK CATEGORY
        const Category = this.categoryKind.filter((el) => kind.Category == el);
        // CHECK ITEM
        const Item = this.itemKind.filter((el) => kind.Item == el.ItemKind);
        // FOR INSERTING CATEGORY
        const InsertCategory = async (Category, Department) =>
          await CorrectionCategoryTable.InsertCategory({
            Category: Category,
            Department: Department,
          });
        // FOR INSERTING ITEM
        const InsertItem = async (Category, ItemKind, Department) =>
          await CorrectionCategoryTable.InsertItemKind({
            Category: Category,
            ItemKind: ItemKind,
            Department: Department,
          });

        // CHECK IF EMPTY CATEGORY
        if (!Category.length) {
          promise.push(
            InsertCategory(kind.Category, this.department),
            InsertItem(kind.Category, kind.Item, this.department)
          );
        } else if (!Item.length)
          promise.push(InsertItem(kind.Category, kind.Item, this.department));

        Promise.all(promise).then(() => this.CategoryItemKind(this.department));
      }
    },

    //********************************//
    //     CHANGE CATEGORY TEXT       //
    //********************************//

    ChangeText(text, type) {
      this.categoryItemKind[type] = text.toUpperCase().split(" ").join("_");
    },

    //*****************************//
    //     GET STOP ASSESSMENT     //
    //*****************************//

    async StopAssessment() {
      await StopTable.Get().then((res) => {
        for (let x of res.data) x.text = `${x.Code} / ${x.Assessment}`;
        this.stopRecord = res.data;
      });
    },

    //************************************************//
    //         GET DUE DATE OF CONFIRMATION           //inquiry
    //************************************************//

    GetDuedate() {
      // console.log(this.information.TypeOfConfirmation)
      HRDSQL.Calendar().then((res) => {
        this.holidaySchedule = res.data;
        let ConfirmationDate = [];
        let dueDate = 0;

        switch (this.information.InquiryKind.Code) {
          case "001":
            dueDate = 3;
            break;
          case "002":
            dueDate = 3;
            break;
          case "014":
            dueDate = 3;
            break;
          case "005":
            dueDate = 1;
            break;
          case "015":
            dueDate = 3;
            break;
        }
        if (
          this.information.InquiryKind.Code == "001" &&
          this.information.JobKind.Code == "APL206"
        ) {
          dueDate = 7;
        }

        // DEFAULT DUE DATE DEPENDS ON INQUIRY KIND

        for (let x = 1; x <= dueDate; x++)
          ConfirmationDate.push(moment().add(x, "days").format("YYYY-MM-DD"));

        ValidateDate(ConfirmationDate);
      });

      const ValidateDate = (date) => {
        let userType = "";
        if (this.CheckUserType == "Planner") userType = "IsHolidayPlanner";
        else if (this.CheckUserType == "Salesman") userType = "IsHolidaySalesman";
        else userType = "IsHolidayJapan"; // Chnage 01/06/2024

        // CHECK IF THERE IS HOLIDAY IN 3 DAY CONFIRMATION DATE
        const checkHoliday = this.holidaySchedule.filter((el) =>
          date.some((date) => el.CalendarDate == date && el[userType])
        ).length;

        // FOR TH DUE DATE
        if (this.information.InquiryKind.Code == "003") {
          if (this.due) return;
          return this.$set(
            this.information,
            "TypeOfConfirmation",
            moment().format("YYYY-MM-DD")
          );
        }

        // CHECK IF DATE IS AVAILABLE
        if (checkHoliday === 0) {
          if (this.due) return;
          return this.$set(this.information, "TypeOfConfirmation", date[date.length - 1]);
        }

        // ADD DAY DEPENDS ON AFFTECTED HOLIDAY OR SUNDAY
        let addtionalDays = [];
        for (let x = 1; x <= checkHoliday; x++)
          addtionalDays.push(
            moment(date[date.length - 1])
              .add(x, "days")
              .format("YYYY-MM-DD")
          );

        ValidateDate(addtionalDays);
      };
    },

    //************************************************//
    //        GET ALLOWED DATE TO BE SELECTED         //
    //************************************************//

    allowedDates(val) {
      let ArrHoliday = [];
      const code =
        this.information.InquiryKind == undefined
          ? ""
          : this.information.InquiryKind.Code;

      if (code == "003") ArrHoliday = this.filterHoliday("IsHolidayHrd");
      else if (this.CheckUserType == "Planner")
        ArrHoliday = this.filterHoliday("IsHolidayPlanner");
      else if (this.CheckUserType == "Salesman")
        ArrHoliday = this.filterHoliday("IsHolidaySalesman");
      else ArrHoliday = this.filterHoliday("IsHolidayPlanner");

      // CHECK FOR ALLOWED DATES AND HOLIDAYS

      const toBeReturn = ArrHoliday.filter((el) => el.Date == val);

      return toBeReturn.length > 0 ? (toBeReturn[0].Restday ? false : true) : false;
    },

    //************************************************//
    //       FILTER ALLOW DATE TO BE SELECTED         //
    //************************************************//

    filterHoliday(type) {
      // CONSTRUCT ARRAY OBJ FOR HOLIDAYS
      return this.holidaySchedule.map((el) => {
        return {
          Date: el.CalendarDate,
          Restday: el[type],
        };
      });
    },
  },
};

</script>

<style>
@font-face {
  font-family: 'MS Gothic';
  src: url('../../../z_systemFont/gaigotg.woff');
}

.woffClass{
  font-family:'MS Gothic';
}


.myCardCorrection {
  max-height: calc(150vh - 300px);
  background-color: #fbfbfb;
}
</style>

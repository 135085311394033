import axios from "axios";
import keys from "../../alert";

export default {
  //********************************************//
  //  GET REQUEST IN CORRECTION CATEGORY TABLE  //
  //********************************************//
  GetCategory(department) {
    return Promise.resolve(
      axios.get(
        `${keys.auth.BaseUrl}/correctionCategory/${department}`,
        keys.auth.apiKey
      )
    );
  },

  //***********************************************//
  //  INSERT REQUEST IN CORRECTION CATEGORY TABLE  //
  //***********************************************//

  InsertCategory(data) {
    return Promise.resolve(
      axios.post(
        `${keys.auth.BaseUrl}/correctionCategory`,
        { data: data },
        keys.auth.apiKey
      )
    );
  },

  //***********************************************//
  //  UPDATE REQUEST IN CORRECTION CATEGORY TABLE  //
  //***********************************************//

  UpdateCategory(data) {
    return Promise.resolve(
      axios.put(
        `${keys.auth.BaseUrl}/correctionCategory`,
        { data: data },
        keys.auth.apiKey
      )
    );
  },

  //-------------------------- //
  //-------------------------- //
  //-------------------------- //

  //********************************************//
  //  GET REQUEST IN CORRECTION CATEGORY TABLE  //
  //********************************************//
  GetItemKind(department) {
    return Promise.resolve(
      axios.get(
        `${keys.auth.BaseUrl}/correctionItemKind/${department}`,
        keys.auth.apiKey
      )
    );
  },

  //***********************************************//
  //  INSERT REQUEST IN CORRECTION CATEGORY TABLE  //
  //***********************************************//

  InsertItemKind(data) {
    return Promise.resolve(
      axios.post(
        `${keys.auth.BaseUrl}/correctionItemKind`,
        { data: data },
        keys.auth.apiKey
      )
    );
  },

  //***********************************************//
  //  UPDATE REQUEST IN CORRECTION CATEGORY TABLE  //
  //***********************************************//

  UpdateItemKind(data) {
    return Promise.resolve(
      axios.put(
        `${keys.auth.BaseUrl}/correctionItemKind`,
        { data: data },
        keys.auth.apiKey
      )
    );
  },
};

import axios from "axios";
import keys from "../../alert";

export default {
  //*******************************************//
  //  GET REQUEST IN STOP ASSESSMENT TABLE    //
  //******************************************//

  Get(data) {
    return Promise.resolve(
      axios.get(
        `${keys.auth.BaseUrl}/stopAssessment`,
        { data: data },
        keys.auth.apiKey
      )
    );
  },
};

<template>
  <div>
    <v-btn
      v-if="checkCode.length == 12"
      color="#df6464"
      outlined
      small
      @click.native="dialogTo = !dialogTo"
    >
      To
    </v-btn>
    <v-dialog v-model="dialogTo" width="1100">
      <v-card>
        <v-container>
          <v-row>
            <!-- CUSTOMER INFORMATION -->
            <v-col class="pb-5">
              <v-list-item-title
                class="text-center"
                style="font-weight: bold; font-size: 14px"
                >Customer Code Information
              </v-list-item-title>
              <v-divider class="mb-4 mt-2" />

              <v-checkbox
                v-if="EmailInformation.PlannerEmail"
                v-model="EmailAddress"
                :value="EmailInformation.PlannerEmail"
                class="mb-n7"
                :label="`Planner ( ${EmailInformation.Planner} )`"
                color="#df6464"
                multiple
              ></v-checkbox>
              <v-checkbox
                v-if="EmailInformation.SalesmanEmail"
                v-model="EmailAddress"
                :value="EmailInformation.SalesmanEmail"
                class="mb-n7"
                :label="`Salesman ( ${EmailInformation.Salesman} )`"
                color="#df6464"
                multiple
              ></v-checkbox>
              <v-checkbox
                v-if="EmailInformation.LicensePlannerEmail"
                v-model="EmailAddress"
                :value="EmailInformation.LicensePlannerEmail"
                class="mb-n7"
                :label="`License Planner ( ${EmailInformation.LicensePlanner} )`"
                color="#df6464"
                multiple
              ></v-checkbox>
              <v-checkbox
                v-if="EmailInformation.SuperIntendentEmail"
                v-model="EmailAddress"
                :value="EmailInformation.SuperIntendentEmail"
                class="mb-n7"
                :label="`Superintendent ( ${EmailInformation.SuperIntendent} )`"
                color="#df6464"
                multiple
              ></v-checkbox>
              <v-checkbox
                v-if="EmailInformation.FirstEmail"
                v-model="EmailAddress"
                :value="EmailInformation.FirstEmail"
                class="mb-n7"
                :label="`First ( ${EmailInformation.First} )`"
                color="#df6464"
                multiple
              ></v-checkbox>
              <v-checkbox
                v-if="EmailInformation.MiddleEmail"
                v-model="EmailAddress"
                :value="EmailInformation.MiddleEmail"
                class="mb-n7"
                :label="`Middle ( ${EmailInformation.Middle} )`"
                color="#df6464"
                multiple
              ></v-checkbox>
              <v-checkbox
                v-if="EmailInformation.FinishEmail"
                v-model="EmailAddress"
                :value="EmailInformation.FinishEmail"
                class="mb-n7"
                :label="`Finished ( ${EmailInformation.Finish} )`"
                color="#df6464"
                multiple
              ></v-checkbox>
              <v-checkbox
                v-if="EmailInformation.ConstBranchEmail"
                v-model="EmailAddress"
                :value="EmailInformation.ConstBranchEmail"
                class="mb-n7"
                :label="`ConstBranch ( ${EmailInformation.ConstBranch} )`"
                color="#df6464"
                multiple
              ></v-checkbox>
              <v-checkbox
                v-if="EmailInformation.DesignOfficeEmail"
                v-model="EmailAddress"
                :value="EmailInformation.DesignOfficeEmail"
                :label="`DesignOffice ( ${EmailInformation.DesignOffice} )`"
                color="#df6464"
                multiple
              ></v-checkbox>
            </v-col>
            <v-divider vertical></v-divider>
            <!-- GROUP -->
            <v-col>
              <v-list-item-title
                class="text-center"
                style="font-weight: bold; font-size: 14px"
                >Group
              </v-list-item-title>
              <v-divider class="mb-4 mt-2" />
              <v-text-field
                v-model="groupText"
                class="mb-n6"
                label="Search Group"
                color="#df6464"
                outlined
                dense
              ></v-text-field>
              <div class="text-center" style="height: 400px" v-scrollbar>
                <div v-if="filteredGroup.length">
                  <v-checkbox
                    v-model="Group"
                    v-for="(val, i) in filteredGroup"
                    :key="i"
                    :value="val.GroupName"
                    :label="val.GroupName"
                    class="mb-n7"
                    color="#df6464"
                    multiple
                  />
                </div>
                <div v-else>
                  <span style="color: red; font-weight: bold; font-size: 20px"
                    >No Record Found</span
                  >
                </div>
              </div>
            </v-col>
            <v-divider vertical></v-divider>
            <!-- ADDRESSES -->
            <v-col>
              <v-list-item-title
                class="text-center"
                style="font-weight: bold; font-size: 14px"
                >Addresses</v-list-item-title
              >
              <v-divider class="mb-4 mt-2" />
              <v-text-field
                v-model="addressText"
                class="mb-n6"
                label="Search Address"
                color="#df6464"
                outlined
                dense
              ></v-text-field>

              <div class="text-center" style="height: 400px" v-scrollbar>
                <div v-if="filteredAddress.length">
                  <v-checkbox
                    v-model="Addresses"
                    v-for="(val, i) in filteredAddress"
                    :key="i"
                    :value="val.Email"
                    :label="`${val.To} / ${val.Email}`"
                    class="mb-n7"
                    color="#df6464"
                    multiple
                  />
                </div>
                <div v-else>
                  <span style="color: red; font-weight: bold; font-size: 20px"
                    >No Record Found</span
                  >
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import store from "@/store";
import hrdSql from "../../functions/httpRequest/ExternalConnection/hrdSql";
import allConnection from "../../functions/httpRequest/hrdappsAPI/allConnection";
import CorrectionUserTable from "../../functions/httpRequest/ExternalConnection/CorrectionRequestTable";
import objects from "../../functions/alert.js";

// import objects from "../../functions/alert.js";

export default {
  data() {
    return {
      PlannerLeaderEmail: "",
      PlannerLeader: "",
      dialogTo: false,
      checkCode: " ",
      addressText: "",
      groupText: "",
      EmployeeCode: store.state.userinfo.EmployeeCode,
      team: store.state.userinfo.Team,
      department: store.state.userinfo.Department,
      section: store.state.userinfo.Section,
      SenderEmail: store.state.userinfo.Email,
      userInformation: {},
      CustomerInfo: ["Planner", "Salesman", "First", "Middle"],
      EmailAddress: [],
      Addresses: [],
      Group: [],
      EmailInformation: [],
    };
  },
  // DATA FROM PARENT COMPONENT
  props: {
    controlNumber: {
      type: String,
    },
    To: {
      type: Array,
    },
  },
  created() {
    this.GetAddresses();
  },
  computed: {
    //************************************//
    //   RESULT VALUE OF ADDRESS SEARCH   //
    //************************************//

    filteredAddress() {
      let filter = [];
      if (this.userInformation.To)
        filter = this.userInformation.To.filter((el) =>
          el.To.toLowerCase().includes(this.addressText.toLowerCase())
        );
      return filter;
    },
    //************************************//
    //   RESULT VALUE OF ADDRESS GROUP   //
    //************************************//

    filteredGroup() {
      let filter = [];
      if (this.userInformation.Group)
        filter = this.userInformation.Group.filter((el) =>
          el.GroupName.toLowerCase().includes(this.groupText.toLowerCase())
        );
      return filter;
    },
  },
  watch: {
    //***********************************//
    //        CLEAR SEARCH BAR           //
    //***********************************//

    dialogTo(newval) {
      if (!newval) {
        this.addressText = "";
        this.groupText = "";
      }
    },
    //*********************************************//
    //  CHECK FOR CUSTOMER INFO ADDRESS CHANGES    //
    //*********************************************//

    EmailAddress(oldVal, newVal) {
      let EmailtoSend = [];

      // INSERT EMAIL AND NAME BASED ON EMAIL INFORMATION
      for (let x in this.EmailAddress) {
        let stop = false;
        if (stop) break;
        for (let i of Object.keys(this.EmailInformation)) {
          // CHECK FOR SAME EMAIL
          if (this.EmailInformation[i] == this.EmailAddress[x]) {
            const keyName = i.substring(0, i.length - 5);
            // CHECK FOR SAME OBJECT NAME
            for (let y of Object.keys(this.EmailInformation)) {
              if (keyName == y) {
                EmailtoSend.push({
                  Name: this.EmailInformation[y],
                  Email: this.EmailAddress[x],
                });
                break;
              }
            }
            stop = true;
            break;
          }
          // console.log(this.EmailAddress[x])
        }
      }

      // PASS RECORD IN PARENT COMPONENT
      this.$emit("passEmail", EmailtoSend, oldVal, newVal);
    },
    //***********************************//
    //  CHECK FOR GROUP ADDRESS CHANGES  //
    //***********************************//

    Group(newval, oldval) {
      let To = [];
      let Cc = [];

      const filterGroup = this.userInformation.Group.filter((el) =>
        newval.some((child) => child == el.GroupName)
      );

      for (let x in filterGroup) {
        for (let y in filterGroup[x].info) {
          if (y == "Cc") {
            let cc = [];
            let emailCc = [];

            if (filterGroup[x].info.Cc) {
              cc = filterGroup[x].info.Cc.split(",");
              emailCc = filterGroup[x].info.CcEmail.split(",");
            }

            if (cc.length) {
              for (let z in cc) {
                if (cc[z])
                  Cc.push({
                    Name: cc[z],
                    Email: emailCc[z],
                  });
              }
            }
          }

          if (y == "To") {
            let to = [];
            let emailTo = [];

            if (filterGroup[x].info.To) {
              to = filterGroup[x].info.To.split(",");
              emailTo = filterGroup[x].info.ToEmail.split(",");
            }

            if (to.length) {
              this.CheckForCodeInfo(to);
              to = to.filter((el) => {
                const check = this.CustomerInfo.findIndex((child) => child == el);
                return check >= 0 ? false : true;
              });

              for (let z in to) {
                if (to[z])
                  To.push({
                    Name: to[z],
                    Email: emailTo[z],
                  });
              }
            }
          }
        }
      }

      // PASS RECORD IN PARENT COMPONENT
      this.$emit("passGroup", To, Cc, newval, oldval);
    },
    //*************************************//
    //  CHECK FOR GROUP ADDRESSES CHANGES  //
    //*************************************//

    Addresses(newval, oldval) {
      let EmailtoSend = [];

      for (let email of newval) {
        const check = this.userInformation.To.filter((el) => el.Email == email)[0];
        EmailtoSend.push({
          Email: check.Email,
          Name: check.To,
        });
      }

      // PASS RECORD IN PARENT COMPONENT
      this.$emit("passEmailAddresses", EmailtoSend, newval, oldval);
    },
    //*********************************//
    // WATCH FOR EMPTY CONTROL NUMBER  //
    //*********************************//

    controlNumber(newval) {
      if (newval != undefined) if (newval.length == 12) this.GetEmail();
      this.EmailAddress = [];
      this.checkCode = this.controlNumber || " ";
    },
  },
  methods: {
    //************************//
    //   CLEAR ALL ARRAY DATA //
    //************************//

    ClearData() {
      this.EmailAddress = [];
      this.Addresses = [];
      this.Group = [];
    },
    //****************************************//
    //    CHECK IF THERE IS UNCHECK IN GROUP  //
    //****************************************//

    getGroupUncheck(val) {
      const group = this.userInformation.Group.filter((el) => val == el.GroupName);

      // REMOVE CI INFORMATION IF THERE'S ANY
      const ciInfo = group[0].info.ToEmail.split(",").filter((el) =>
        this.CustomerInfo.includes(el)
      );

      if (ciInfo.length)
        this.EmailAddress = this.EmailAddress.filter((el) => {
          const type = ciInfo.map((el) => this.EmailInformation[`${el}Email`]);
          const check = type.findIndex((find) => find == el);
          return check >= 0 ? false : true;
        });

      return group;
    },

    //**************************************************//
    //    CHECK IF THERE IS DEFAULT PLANNER OR SALESMAN  1//
    //***************************************************//

    async CheckForCodeInfo(arr) {
      // console.log(arr);
      if (
        this.SenderEmail == "cad-mistake@hrd-s.com" ||
        this.SenderEmail == "cad@hrd-s.com"
      ) {
        if (arr.length) {
          arr.forEach(async (el) => {
            if (
              el === "Planner" ||
              el === "Middle" ||
              el === "First" ||
              el === "Plumber" ||
              el === "ExteriorSubcon1st" ||
              el === "ExteriorSubcon2nd" ||
              el === "PlannerLeader"
            ) {
              console.log(this.EmailInformation, "tryyyyy");
              this.EmailInformation[el + "Email"]
                ? this.EmailAddress.push(this.EmailInformation[el + "Email"])
                : this.EmailAddress.push(this.EmailInformation.SalesmanEmail);
            }
            // else if (el === "PlannerLeader") {
            //   console.log(this.PlannerInformation);
            //   this.PlannerInformation[0].PlannerLeaderEmail
            //     ? this.EmailAddress.push(
            //         this.PlannerInformation[0].PlannerLeaderEmail
            //       )
            //     : this.EmailAddress.push(
            //         this.PlannerInformation[0].PlannerLeaderEmail
            //       );
            //   console.log(this.EmailAddress);
            //   // console.log("447");
            //   // this.PlannerEmail = {};
            //   // allConnection.CustomerCode(this.controlNumber).then((res) => {
            //   //   var planner = res[0];
            //   //   planner.el + "Email"
            //   //     ? this.EmailAddress.push(planner.el + "Email")
            //   //     : this.EmailAddress.push(this.EmailInformation.SalesmanEmail);

            //   //   // this.PlannerEmail=res.data
            //   // });
            //   //  this.PlannerEmail[el + "Email"]
            //   //   ? this.EmailAddress.push(this.PlannerEmail[el + "Email"])
            //   //   : this.EmailAddress.push(this.PlannerEmail.PlannerLeaderEmail);
            // }

            // console.log(this.EmailInformation.SalesmanEmail)
            // else if(
            //   el === ""
            // ){

            //   this.EmailAddress.push(this.EmailInformation.Salesman)
            // }
          });
          // console.log(this.EmailAddress);
        } else this.EmailAddress = [];
      } else {
        if (arr.length) {
          arr.forEach(async (el) => {
            if (
              el === "Planner" ||
              el === "Middle" ||
              el === "First" ||
              el === "Plumber" ||
              el === "ExteriorSubcon1st" ||
              el === "ExteriorSubcon2nd" ||
              el === "PlannerLeader" ||
              el === "DesignOffice"
            ) {
              console.log(this.EmailInformation, "tryyyyy");
              this.EmailInformation[el + "Email"]
                ? this.EmailAddress.push(this.EmailInformation[el + "Email"])
                : this.$toast(` No ${el} Email Available (To  )`, objects.alert);
            }
            // else if (el === "PlannerLeader") {
            //   console.log(this.PlannerInformation);
            //   this.PlannerInformation[0].PlannerLeaderEmail
            //     ? this.EmailAddress.push(
            //         this.PlannerInformation[0].PlannerLeaderEmail
            //       )
            //     : this.EmailAddress.push(
            //         this.PlannerInformation[0].PlannerLeaderEmail
            //       );
            //   console.log(this.EmailAddress);
            //   // console.log("447");
            //   // this.PlannerEmail = {};
            //   // allConnection.CustomerCode(this.controlNumber).then((res) => {
            //   //   var planner = res[0];
            //   //   planner.el + "Email"
            //   //     ? this.EmailAddress.push(planner.el + "Email")
            //   //     : this.EmailAddress.push(this.EmailInformation.SalesmanEmail);

            //   //   // this.PlannerEmail=res.data
            //   // });
            //   //  this.PlannerEmail[el + "Email"]
            //   //   ? this.EmailAddress.push(this.PlannerEmail[el + "Email"])
            //   //   : this.EmailAddress.push(this.PlannerEmail.PlannerLeaderEmail);
            // }

            // console.log(this.EmailInformation.SalesmanEmail)
            // else if(
            //   el === ""
            // ){

            //   this.EmailAddress.push(this.EmailInformation.Salesman)
            // }
          });
          // console.log(this.EmailAddress);
        } else this.EmailAddress = [];
      }
    },

    //*******************************//
    //   GET CUSTOMER INFORMATION    //
    //*******************************//

    async GetEmail() {
      this.EmailInformation = {};

      if (this.controlNumber) {
        await allConnection.CustomerCode(this.controlNumber).then((res) => {
          let getPlannerLeader = res.filter((rec) => {
            return rec;
          });
          this.PlannerLeader = getPlannerLeader[0].PlannerLeader;
          this.PlannerLeaderEmail = getPlannerLeader[0].PlannerLeaderEmail;
          this.EmailInformation;
        });
        //GET EMAIL INFORMATION
        await hrdSql.ConstructionEmailInformation(this.controlNumber).then((res) => {
          res.data.PlannerLeader = this.PlannerLeader ? this.PlannerLeader : "";
          res.data.PlannerLeaderEmail = this.PlannerLeaderEmail
            ? this.PlannerLeaderEmail
            : this.PlannerLeaderEmail;
          this.EmailInformation = res.data;
        });
        // res.data[0]
        await allConnection
          .CustomerInformation(this.controlNumber)
          .then(async (customerInfo) => {
            await allConnection
              .ConstBranchOffice(customerInfo[0].ConstExhibitionCode)
              .then((exhibitionInfo) => {
                this.$set(
                  this.EmailInformation,
                  "ConstBranchEmail",
                  exhibitionInfo[0].MailAddress || exhibitionInfo[0].MailAddress2
                );

                this.EmailInformation.ConstBranch =
                  exhibitionInfo[0].ContractExhibitionName;
              });
          });

        await allConnection.DesignOffice(this.controlNumber).then((designOffice) => {
          this.$set(
            this.EmailInformation,
            "DesignOfficeEmail",
            designOffice[0].MailAddress
          );

          this.EmailInformation.DesignOffice = designOffice[0].TraderName1;
        });
      }
    },

    //***************************//
    //   GET USER INFORMATION    //
    //***************************//

    GetAddresses() {
      CorrectionUserTable.GetSpecificUser(this.EmployeeCode).then((res) => {
        this.userInformation = { ...res.data[0] };
        this.userInformation.To = JSON.parse(this.userInformation.To);
        this.userInformation.Group = this.userInformation.Group
          ? JSON.parse(this.userInformation.Group)
          : [];
      });
    },
  },
};
</script>

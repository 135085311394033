const arrayOfNeedFollowUp =[
  {
    "Department": "CAD Planning 1",
    "Section": "I-Smile Planning",
    "Code": "'006'",
    "EnglishName": "Information",
    "JapaneseName": "連絡事項",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 1",
    "Section": "I-Smile Planning",
    "Code": "'009'",
    "EnglishName": "CAD Urgent verification",
    "JapaneseName": "CAD緊急確認",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 1",
    "Section": "I-Smile Planning",
    "Code": "'018'",
    "EnglishName": "Cancellation information",
    "JapaneseName": "キャンセル連絡事項",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 1",
    "Section": "Plan Administration",
    "Code": "'009'",
    "EnglishName": "CAD Urgent verification",
    "JapaneseName": "CAD緊急確認",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 1",
    "Section": "Plan Administration",
    "Code": "'006'",
    "EnglishName": "Information",
    "JapaneseName": "連絡事項",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 1",
    "Section": "Plan Administration",
    "Code": "'018'",
    "EnglishName": "Cancellation information",
    "JapaneseName": "キャンセル連絡事項",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 1",
    "Section": "Technical Support",
    "Code": "'009'",
    "EnglishName": "CAD Urgent verification",
    "JapaneseName": "CAD緊急確認",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 1",
    "Section": "Technical Support",
    "Code": "'018'",
    "EnglishName": "Cancellation information",
    "JapaneseName": "キャンセル連絡事項",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 1",
    "Section": "Technical Support",
    "Code": "'006'",
    "EnglishName": "Information",
    "JapaneseName": "連絡事項",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 1",
    "Section": "Waku Planning 1",
    "Code": "'009'",
    "EnglishName": "CAD Urgent verification",
    "JapaneseName": "CAD緊急確認",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 1",
    "Section": "Waku Planning 1",
    "Code": "'006'",
    "EnglishName": "Information",
    "JapaneseName": "連絡事項",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 1",
    "Section": "Waku Planning 1",
    "Code": "'018'",
    "EnglishName": "Cancellation information",
    "JapaneseName": "キャンセル連絡事項",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 1",
    "Section": "Waku Planning 2",
    "Code": "'009'",
    "EnglishName": "CAD Urgent verification",
    "JapaneseName": "CAD緊急確認",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 1",
    "Section": "Waku Planning 2",
    "Code": "'006'",
    "EnglishName": "Information",
    "JapaneseName": "連絡事項",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 1",
    "Section": "Waku Planning 2",
    "Code": "'018'",
    "EnglishName": "Cancellation information",
    "JapaneseName": "キャンセル連絡事項",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 1",
    "Section": "Technical Support",
    "Code": "'009A'",
    "EnglishName": "CAD Urgent verification",
    "JapaneseName": "CAD緊急確認",
    "NeedFollowUp": "YES",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 1",
    "Section": "I-Smile Planning",
    "Code": "'009A'",
    "EnglishName": "CAD Urgent verification",
    "JapaneseName": "CAD緊急確認",
    "NeedFollowUp": "YES",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 1",
    "Section": "Waku Planning 1",
    "Code": "'009A'",
    "EnglishName": "CAD Urgent verification",
    "JapaneseName": "CAD緊急確認",
    "NeedFollowUp": "YES",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 1",
    "Section": "Waku Planning 2",
    "Code": "'009A'",
    "EnglishName": "CAD Urgent verification",
    "JapaneseName": "CAD緊急確認",
    "NeedFollowUp": "YES",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 1",
    "Section": "Plan Administration",
    "Code": "'009A'",
    "EnglishName": "CAD Urgent verification",
    "JapaneseName": "CAD緊急確認",
    "NeedFollowUp": "YES",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 1",
    "Section": "Technical Support",
    "Code": "'009B'",
    "EnglishName": "CAD Urgent verification",
    "JapaneseName": "CAD緊急確認",
    "NeedFollowUp": "YES",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 1",
    "Section": "I-Smile Planning",
    "Code": "'009B'",
    "EnglishName": "CAD Urgent verification",
    "JapaneseName": "CAD緊急確認",
    "NeedFollowUp": "YES",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 1",
    "Section": "Waku Planning 1",
    "Code": "'009B'",
    "EnglishName": "CAD Urgent verification",
    "JapaneseName": "CAD緊急確認",
    "NeedFollowUp": "YES",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 1",
    "Section": "Waku Planning 2",
    "Code": "'009B'",
    "EnglishName": "CAD Urgent verification",
    "JapaneseName": "CAD緊急確認",
    "NeedFollowUp": "YES",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 1",
    "Section": "Plan Administration",
    "Code": "'009B'",
    "EnglishName": "CAD Urgent verification",
    "JapaneseName": "CAD緊急確認",
    "NeedFollowUp": "YES",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 1",
    "Section": "Technical Support",
    "Code": "'009C'",
    "EnglishName": "CAD Urgent verification",
    "JapaneseName": "CAD緊急確認",
    "NeedFollowUp": "YES",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 1",
    "Section": "I-Smile Planning",
    "Code": "'009C'",
    "EnglishName": "CAD Urgent verification",
    "JapaneseName": "CAD緊急確認",
    "NeedFollowUp": "YES",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 1",
    "Section": "Waku Planning 1",
    "Code": "'009C'",
    "EnglishName": "CAD Urgent verification",
    "JapaneseName": "CAD緊急確認",
    "NeedFollowUp": "YES",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 1",
    "Section": "Waku Planning 2",
    "Code": "'009C'",
    "EnglishName": "CAD Urgent verification",
    "JapaneseName": "CAD緊急確認",
    "NeedFollowUp": "YES",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 1",
    "Section": "Plan Administration",
    "Code": "'009C'",
    "EnglishName": "CAD Urgent verification",
    "JapaneseName": "CAD緊急確認",
    "NeedFollowUp": "YES",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 2",
    "Section": "CAD Training",
    "Code": "'009'",
    "EnglishName": "CAD Urgent verification",
    "JapaneseName": "CAD緊急確認",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 2",
    "Section": "CAD Training",
    "Code": "'009A'",
    "EnglishName": "CAD Urgent verification",
    "JapaneseName": "CAD緊急確認",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 2",
    "Section": "CAD Wakugumi A",
    "Code": "'009A'",
    "EnglishName": "CAD Urgent verification",
    "JapaneseName": "CAD緊急確認",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 2",
    "Section": "CAD Wakugumi B",
    "Code": "'009A'",
    "EnglishName": "CAD Urgent verification",
    "JapaneseName": "CAD緊急確認",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 2",
    "Section": "CAD Wakugumi C",
    "Code": "'009A'",
    "EnglishName": "CAD Urgent verification",
    "JapaneseName": "CAD緊急確認",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 2",
    "Section": "CAD Training",
    "Code": "'009B'",
    "EnglishName": "CAD Urgent verification",
    "JapaneseName": "CAD緊急確認",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 2",
    "Section": "CAD Wakugumi B",
    "Code": "'009B'",
    "EnglishName": "CAD Urgent verification",
    "JapaneseName": "CAD緊急確認",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 2",
    "Section": "CAD Wakugumi C",
    "Code": "'009B'",
    "EnglishName": "CAD Urgent verification",
    "JapaneseName": "CAD緊急確認",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 2",
    "Section": "CAD Wakugumi A",
    "Code": "'009B'",
    "EnglishName": "CAD Urgent verification",
    "JapaneseName": "CAD緊急確認",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 2",
    "Section": "CAD Training",
    "Code": "'009C'",
    "EnglishName": "CAD Urgent verification",
    "JapaneseName": "CAD緊急確認",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 2",
    "Section": "CAD Wakugumi B",
    "Code": "'009C'",
    "EnglishName": "CAD Urgent verification",
    "JapaneseName": "CAD緊急確認",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 2",
    "Section": "CAD Wakugumi C",
    "Code": "'009C'",
    "EnglishName": "CAD Urgent verification",
    "JapaneseName": "CAD緊急確認",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 2",
    "Section": "CAD Wakugumi A",
    "Code": "'009C'",
    "EnglishName": "CAD Urgent verification",
    "JapaneseName": "CAD緊急確認",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 2",
    "Section": "CAD Training",
    "Code": "'006'",
    "EnglishName": "Information",
    "JapaneseName": "連絡事項",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 2",
    "Section": "CAD Training",
    "Code": "'018'",
    "EnglishName": "Cancellation information",
    "JapaneseName": "キャンセル連絡事項",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 2",
    "Section": "CAD Wakugumi A",
    "Code": "'009'",
    "EnglishName": "CAD Urgent verification",
    "JapaneseName": "CAD緊急確認",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 2",
    "Section": "CAD Wakugumi A",
    "Code": "'006'",
    "EnglishName": "Information",
    "JapaneseName": "連絡事項",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 2",
    "Section": "CAD Wakugumi A",
    "Code": "'018'",
    "EnglishName": "Cancellation information",
    "JapaneseName": "キャンセル連絡事項",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 2",
    "Section": "CAD Wakugumi B",
    "Code": "'006'",
    "EnglishName": "Information",
    "JapaneseName": "連絡事項",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 2",
    "Section": "CAD Wakugumi B",
    "Code": "'009'",
    "EnglishName": "CAD Urgent verification",
    "JapaneseName": "CAD緊急確認",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 2",
    "Section": "CAD Wakugumi B",
    "Code": "'018'",
    "EnglishName": "Cancellation information",
    "JapaneseName": "キャンセル連絡事項",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 2",
    "Section": "CAD Wakugumi C",
    "Code": "'006'",
    "EnglishName": "Information",
    "JapaneseName": "連絡事項",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 2",
    "Section": "CAD Wakugumi C",
    "Code": "'009'",
    "EnglishName": "CAD Urgent verification",
    "JapaneseName": "CAD緊急確認",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 2",
    "Section": "CAD Wakugumi C",
    "Code": "'018'",
    "EnglishName": "Cancellation information",
    "JapaneseName": "キャンセル連絡事項",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 3",
    "Section": "CAD Jikugumi A",
    "Code": "'009'",
    "EnglishName": "CAD Urgent verification",
    "JapaneseName": "CAD緊急確認",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 3",
    "Section": "Carte Planning",
    "Code": "'009A'",
    "EnglishName": "CAD Urgent verification",
    "JapaneseName": "CAD緊急確認",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 3",
    "Section": "CAD Jikugumi A",
    "Code": "'009A'",
    "EnglishName": "CAD Urgent verification",
    "JapaneseName": "CAD緊急確認",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 3",
    "Section": "CAD Jikugumi B",
    "Code": "'009A'",
    "EnglishName": "CAD Urgent verification",
    "JapaneseName": "CAD緊急確認",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 3",
    "Section": "CAD Jikugumi C",
    "Code": "'009A'",
    "EnglishName": "CAD Urgent verification",
    "JapaneseName": "CAD緊急確認",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 3",
    "Section": "Carte Planning",
    "Code": "'009B'",
    "EnglishName": "CAD Urgent verification",
    "JapaneseName": "CAD緊急確認",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 3",
    "Section": "CAD Jikugumi A",
    "Code": "'009B'",
    "EnglishName": "CAD Urgent verification",
    "JapaneseName": "CAD緊急確認",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 3",
    "Section": "CAD Jikugumi B",
    "Code": "'009B'",
    "EnglishName": "CAD Urgent verification",
    "JapaneseName": "CAD緊急確認",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 3",
    "Section": "CAD Jikugumi C",
    "Code": "'009B'",
    "EnglishName": "CAD Urgent verification",
    "JapaneseName": "CAD緊急確認",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 3",
    "Section": "Carte Planning",
    "Code": "'009C'",
    "EnglishName": "CAD Urgent verification",
    "JapaneseName": "CAD緊急確認",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 3",
    "Section": "CAD Jikugumi A",
    "Code": "'009C'",
    "EnglishName": "CAD Urgent verification",
    "JapaneseName": "CAD緊急確認",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 3",
    "Section": "CAD Jikugumi B",
    "Code": "'009C'",
    "EnglishName": "CAD Urgent verification",
    "JapaneseName": "CAD緊急確認",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 3",
    "Section": "CAD Jikugumi C",
    "Code": "'009C'",
    "EnglishName": "CAD Urgent verification",
    "JapaneseName": "CAD緊急確認",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 3",
    "Section": "CAD Jikugumi A",
    "Code": "'018'",
    "EnglishName": "Cancellation information",
    "JapaneseName": "キャンセル連絡事項",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 3",
    "Section": "CAD Jikugumi A",
    "Code": "'006'",
    "EnglishName": "Information",
    "JapaneseName": "連絡事項",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 3",
    "Section": "CAD Jikugumi A",
    "Code": "'017'",
    "EnglishName": "CARTE Urgent verification",
    "JapaneseName": "CARTE緊急",
    "NeedFollowUp": "",
    "FIELD7": "NOT IN USE"
  },
  {
    "Department": "CAD Planning 3",
    "Section": "CAD Jikugumi B",
    "Code": "'009'",
    "EnglishName": "CAD Urgent verification",
    "JapaneseName": "CAD緊急確認",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 3",
    "Section": "CAD Jikugumi B",
    "Code": "'018'",
    "EnglishName": "Cancellation information",
    "JapaneseName": "キャンセル連絡事項",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 3",
    "Section": "CAD Jikugumi B",
    "Code": "'006'",
    "EnglishName": "Information",
    "JapaneseName": "連絡事項",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 3",
    "Section": "CAD Jikugumi B",
    "Code": "'017'",
    "EnglishName": "CARTE Urgent verification",
    "JapaneseName": "CARTE緊急",
    "NeedFollowUp": "",
    "FIELD7": "NOT IN USE"
  },
  {
    "Department": "CAD Planning 3",
    "Section": "CAD Jikugumi C",
    "Code": "'009'",
    "EnglishName": "CAD Urgent verification",
    "JapaneseName": "CAD緊急確認",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 3",
    "Section": "CAD Jikugumi C",
    "Code": "'006'",
    "EnglishName": "Information",
    "JapaneseName": "連絡事項",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 3",
    "Section": "CAD Jikugumi C",
    "Code": "'018'",
    "EnglishName": "Cancellation information",
    "JapaneseName": "キャンセル連絡事項",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 3",
    "Section": "CAD Jikugumi C",
    "Code": "'017'",
    "EnglishName": "CARTE Urgent verification",
    "JapaneseName": "CARTE緊急",
    "NeedFollowUp": "",
    "FIELD7": "NOT IN USE"
  },
  {
    "Department": "CAD Planning 3",
    "Section": "Carte Planning",
    "Code": "'009'",
    "EnglishName": "CAD Urgent verification",
    "JapaneseName": "CAD緊急確認",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 3",
    "Section": "Carte Planning",
    "Code": "'018'",
    "EnglishName": "Cancellation information",
    "JapaneseName": "キャンセル連絡事項",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 3",
    "Section": "Carte Planning",
    "Code": "'006'",
    "EnglishName": "Information",
    "JapaneseName": "連絡事項",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "CAD Planning 3",
    "Section": "Carte Planning",
    "Code": "'017'",
    "EnglishName": "CARTE Urgent verification",
    "JapaneseName": "CARTE緊急",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "Exterior",
    "Section": "Balcony Floor",
    "Code": "'002'",
    "EnglishName": "Tsuika Henko Shinsei",
    "JapaneseName": "追変提出依頼",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Exterior",
    "Section": "Balcony Floor",
    "Code": "'005'",
    "EnglishName": "Verification",
    "JapaneseName": "確認事項",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "Exterior",
    "Section": "Balcony Floor",
    "Code": "'004'",
    "EnglishName": "CorrectionRequest GC",
    "JapaneseName": "GC是正依頼",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Exterior",
    "Section": "Balcony Floor",
    "Code": "'011'",
    "EnglishName": "Urgent Correction",
    "JapaneseName": "icube緊急確認依頼",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Exterior",
    "Section": "Balcony Floor",
    "Code": "'006'",
    "EnglishName": "Information",
    "JapaneseName": "連絡事項",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Exterior",
    "Section": "Balcony Floor",
    "Code": "'015'",
    "EnglishName": "SC1CorrectionReq.",
    "JapaneseName": "確認依頼",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "Exterior",
    "Section": "Balcony Floor",
    "Code": "'016'",
    "EnglishName": "GC-Correction Req.",
    "JapaneseName": "GC是正依頼",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Exterior",
    "Section": "Balcony Floor",
    "Code": "'003'",
    "EnglishName": "Request for TH",
    "JapaneseName": "追加変更申請",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Exterior",
    "Section": "Balcony Floor",
    "Code": "'001'",
    "EnglishName": "CorrectionRequest",
    "JapaneseName": "確認依頼",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "Exterior",
    "Section": "Balcony Floor",
    "Code": "'014'",
    "EnglishName": "GC BOSS Correction",
    "JapaneseName": "GC確認依頼",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Exterior",
    "Section": "Balcony Wall",
    "Code": "'002'",
    "EnglishName": "Tsuika Henko Shinsei",
    "JapaneseName": "追変提出依頼",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Exterior",
    "Section": "Balcony Wall",
    "Code": "'005'",
    "EnglishName": "Verification",
    "JapaneseName": "確認事項",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "Exterior",
    "Section": "Balcony Wall",
    "Code": "'004'",
    "EnglishName": "CorrectionRequest GC",
    "JapaneseName": "GC是正依頼",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "Exterior",
    "Section": "Balcony Wall",
    "Code": "'011'",
    "EnglishName": "Urgent Correction",
    "JapaneseName": "icube緊急確認依頼",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Exterior",
    "Section": "Balcony Wall",
    "Code": "'015'",
    "EnglishName": "SC1CorrectionReq.",
    "JapaneseName": "確認依頼",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Exterior",
    "Section": "Balcony Wall",
    "Code": "'016'",
    "EnglishName": "GC-Correction Req.",
    "JapaneseName": "GC是正依頼",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Exterior",
    "Section": "Balcony Wall",
    "Code": "'006'",
    "EnglishName": "Information",
    "JapaneseName": "連絡事項",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Exterior",
    "Section": "Balcony Wall",
    "Code": "'001'",
    "EnglishName": "CorrectionRequest",
    "JapaneseName": "確認依頼",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "Exterior",
    "Section": "Balcony Wall",
    "Code": "'003'",
    "EnglishName": "Request for TH",
    "JapaneseName": "追加変更申請",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Exterior",
    "Section": "Balcony Wall",
    "Code": "'014'",
    "EnglishName": "GC BOSS Correction",
    "JapaneseName": "GC確認依頼",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Exterior",
    "Section": "Tilewall A",
    "Code": "'002'",
    "EnglishName": "Tsuika Henko Shinsei",
    "JapaneseName": "追変提出依頼",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Exterior",
    "Section": "Tilewall A",
    "Code": "'005'",
    "EnglishName": "Verification",
    "JapaneseName": "確認事項",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "Exterior",
    "Section": "Tilewall A",
    "Code": "'004'",
    "EnglishName": "CorrectionRequest GC",
    "JapaneseName": "GC是正依頼",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "Exterior",
    "Section": "Tilewall A",
    "Code": "'011'",
    "EnglishName": "Urgent Correction",
    "JapaneseName": "icube緊急確認依頼",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Exterior",
    "Section": "Tilewall A",
    "Code": "'015'",
    "EnglishName": "SC1CorrectionReq.",
    "JapaneseName": "確認依頼",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Exterior",
    "Section": "Tilewall A",
    "Code": "'016'",
    "EnglishName": "GC-Correction Req.",
    "JapaneseName": "GC是正依頼",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "Exterior",
    "Section": "Tilewall A",
    "Code": "'001'",
    "EnglishName": "CorrectionRequest",
    "JapaneseName": "確認依頼",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "Exterior",
    "Section": "Tilewall A",
    "Code": "'003'",
    "EnglishName": "Request for TH",
    "JapaneseName": "追加変更申請",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Exterior",
    "Section": "Tilewall A",
    "Code": "'014'",
    "EnglishName": "GC BOSS Correction",
    "JapaneseName": "GC確認依頼",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Exterior",
    "Section": "Tilewall A",
    "Code": "'006'",
    "EnglishName": "Information",
    "JapaneseName": "連絡事項",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "Exterior",
    "Section": "Tilewall B",
    "Code": "'004'",
    "EnglishName": "CorrectionRequest GC",
    "JapaneseName": "GC是正依頼",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "Exterior",
    "Section": "Tilewall B",
    "Code": "'002'",
    "EnglishName": "Tsuika Henko Shinsei",
    "JapaneseName": "追変提出依頼",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "Exterior",
    "Section": "Tilewall B",
    "Code": "'005'",
    "EnglishName": "Verification",
    "JapaneseName": "確認事項",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "Exterior",
    "Section": "Tilewall B",
    "Code": "'011'",
    "EnglishName": "Urgent Correction",
    "JapaneseName": "icube緊急確認依頼",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "Exterior",
    "Section": "Tilewall B",
    "Code": "'015'",
    "EnglishName": "SC1CorrectionReq.",
    "JapaneseName": "確認依頼",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Exterior",
    "Section": "Tilewall B",
    "Code": "'016'",
    "EnglishName": "GC-Correction Req.",
    "JapaneseName": "GC是正依頼",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "Exterior",
    "Section": "Tilewall B",
    "Code": "'001'",
    "EnglishName": "CorrectionRequest",
    "JapaneseName": "確認依頼",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "Exterior",
    "Section": "Tilewall B",
    "Code": "'003'",
    "EnglishName": "Request for TH",
    "JapaneseName": "追加変更申請",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Exterior",
    "Section": "Tilewall B",
    "Code": "'014'",
    "EnglishName": "GC BOSS Correction",
    "JapaneseName": "GC確認依頼",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Exterior",
    "Section": "Tilewall B",
    "Code": "'006'",
    "EnglishName": "Information",
    "JapaneseName": "連絡事項",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Foundation",
    "Section": "Foundation Application",
    "Code": "'005'",
    "EnglishName": "Verification",
    "JapaneseName": "確認事項",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "Foundation",
    "Section": "Foundation Application",
    "Code": "'006'",
    "EnglishName": "Information",
    "JapaneseName": "連絡事項",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "Foundation",
    "Section": "Foundation Application",
    "Code": "'007'",
    "EnglishName": "Plan Sending",
    "JapaneseName": "資料送付",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Foundation",
    "Section": "Foundation Application",
    "Code": "'003'",
    "EnglishName": "Request for TH",
    "JapaneseName": "追加変更申請",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Foundation",
    "Section": "Foundation Application",
    "Code": "'001'",
    "EnglishName": "CorrectionRequest",
    "JapaneseName": "確認依頼",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "Foundation",
    "Section": "Foundation Application",
    "Code": "'008'",
    "EnglishName": "Plan Checking",
    "JapaneseName": "図面チェック",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Foundation",
    "Section": "Foundation Calculation",
    "Code": "'006'",
    "EnglishName": "Information",
    "JapaneseName": "連絡事項",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "Foundation",
    "Section": "Foundation Calculation",
    "Code": "'005'",
    "EnglishName": "Verification",
    "JapaneseName": "確認事項",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "Foundation",
    "Section": "Foundation Calculation",
    "Code": "'007'",
    "EnglishName": "Plan Sending",
    "JapaneseName": "資料送付",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Foundation",
    "Section": "Foundation Calculation",
    "Code": "'003'",
    "EnglishName": "Request for TH",
    "JapaneseName": "追加変更申請",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Foundation",
    "Section": "Foundation Calculation",
    "Code": "'001'",
    "EnglishName": "CorrectionRequest",
    "JapaneseName": "確認依頼",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "Foundation",
    "Section": "Foundation Calculation",
    "Code": "'008'",
    "EnglishName": "Plan Checking",
    "JapaneseName": "図面チェック",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Foundation",
    "Section": "Menshin",
    "Code": "'005'",
    "EnglishName": "Verification",
    "JapaneseName": "確認事項",
    "NeedFollowUp": "",
    "FIELD7": "not in use "
  },
  {
    "Department": "Foundation",
    "Section": "Menshin",
    "Code": "'008'",
    "EnglishName": "Plan Checking",
    "JapaneseName": "図面チェック",
    "NeedFollowUp": "",
    "FIELD7": "not in use "
  },
  {
    "Department": "Foundation",
    "Section": "Menshin",
    "Code": "'006'",
    "EnglishName": "Information",
    "JapaneseName": "連絡事項",
    "NeedFollowUp": "",
    "FIELD7": "not in use "
  },
  {
    "Department": "Foundation",
    "Section": "Menshin",
    "Code": "'007'",
    "EnglishName": "Plan Sending",
    "JapaneseName": "資料送付",
    "NeedFollowUp": "",
    "FIELD7": "not in use "
  },
  {
    "Department": "Foundation",
    "Section": "Menshin",
    "Code": "'003'",
    "EnglishName": "Request for TH",
    "JapaneseName": "追加変更申請",
    "NeedFollowUp": "",
    "FIELD7": "not in use "
  },
  {
    "Department": "Foundation",
    "Section": "Menshin",
    "Code": "'001'",
    "EnglishName": "CorrectionRequest",
    "JapaneseName": "確認依頼",
    "NeedFollowUp": "",
    "FIELD7": "not in use "
  },
  {
    "Department": "Frame",
    "Section": "Bearing Wall - Jikugumi",
    "Code": "'004'",
    "EnglishName": "CorrectionRequest GC",
    "JapaneseName": "GC是正依頼",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "Frame",
    "Section": "Bearing Wall - Jikugumi",
    "Code": "'001'",
    "EnglishName": "CorrectionRequest",
    "JapaneseName": "確認依頼",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "Frame",
    "Section": "Bearing Wall - Jikugumi",
    "Code": "'003'",
    "EnglishName": "Request for TH",
    "JapaneseName": "追加変更申請",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Frame",
    "Section": "Bearing Wall - Jikugumi",
    "Code": "'006'",
    "EnglishName": "Information",
    "JapaneseName": "連絡事項",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Frame",
    "Section": "Frame Estimation",
    "Code": "'006'",
    "EnglishName": "Information",
    "JapaneseName": "連絡事項",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Frame",
    "Section": "Frame Estimation",
    "Code": "'001'",
    "EnglishName": "CorrectionRequest",
    "JapaneseName": "確認依頼",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "Frame",
    "Section": "Frame Estimation",
    "Code": "'003'",
    "EnglishName": "Request for TH",
    "JapaneseName": "追加変更申請",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Frame",
    "Section": "Frame Estimation",
    "Code": "'004'",
    "EnglishName": "CorrectionRequest GC",
    "JapaneseName": "GC是正依頼",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "Frame",
    "Section": "Kouzou Jikugumi",
    "Code": "'001'",
    "EnglishName": "CorrectionRequest",
    "JapaneseName": "確認依頼",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "Frame",
    "Section": "Kouzou Jikugumi",
    "Code": "'003'",
    "EnglishName": "Request for TH",
    "JapaneseName": "追加変更申請",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Frame",
    "Section": "Kouzou Jikugumi",
    "Code": "'006'",
    "EnglishName": "Information",
    "JapaneseName": "連絡事項",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Frame",
    "Section": "Kouzou Jikugumi",
    "Code": "'004'",
    "EnglishName": "CorrectionRequest GC",
    "JapaneseName": "GC是正依頼",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "Frame",
    "Section": "MPCAD",
    "Code": "'006'",
    "EnglishName": "Information",
    "JapaneseName": "連絡事項",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Frame",
    "Section": "MPCAD",
    "Code": "'001'",
    "EnglishName": "CorrectionRequest",
    "JapaneseName": "確認依頼",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "Frame",
    "Section": "MPCAD",
    "Code": "'003'",
    "EnglishName": "Request for TH",
    "JapaneseName": "追加変更申請",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Frame",
    "Section": "MPCAD",
    "Code": "'004'",
    "EnglishName": "CorrectionRequest GC",
    "JapaneseName": "GC是正依頼",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Gaibu Hiroi",
    "Code": "'010'",
    "EnglishName": "Ashiba Plan sending",
    "JapaneseName": "足場資料送付",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Gaibu Hiroi",
    "Code": "'005'",
    "EnglishName": "Verification",
    "JapaneseName": "確認事項",
    "NeedFollowUp": "YES",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Gaibu Hiroi",
    "Code": "'003'",
    "EnglishName": "Request for TH",
    "JapaneseName": "追加変更申請",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Gaibu Hiroi",
    "Code": "'006'",
    "EnglishName": "Information",
    "JapaneseName": "連絡事項",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Gaibu Hiroi",
    "Code": "'001'",
    "EnglishName": "CorrectionRequest",
    "JapaneseName": "確認依頼",
    "NeedFollowUp": "YES",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Gaibu Hiroi",
    "Code": "'002'",
    "EnglishName": "Tsuika Henko Shinsei",
    "JapaneseName": "追変提出依頼",
    "NeedFollowUp": "YES",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Gaibu Hiroi",
    "Code": "'004'",
    "EnglishName": "CorrectionRequest GC",
    "JapaneseName": "GC是正依頼",
    "NeedFollowUp": "YES",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Gaibu Hiroi",
    "Code": "'008'",
    "EnglishName": "Plan Checking",
    "JapaneseName": "図面チェック",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Gaibu Hiroi",
    "Code": "'007'",
    "EnglishName": "Plan Sending",
    "JapaneseName": "資料送付",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Gaibu Hiroi",
    "Code": "'014'",
    "EnglishName": "GC BOSS Correction",
    "JapaneseName": "GC確認依頼",
    "NeedFollowUp": "YES",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Gaibu Shiage",
    "Code": "'003'",
    "EnglishName": "Request for TH",
    "JapaneseName": "追加変更申請",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Gaibu Shiage",
    "Code": "'005'",
    "EnglishName": "Verification",
    "JapaneseName": "確認事項",
    "NeedFollowUp": "YES",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Gaibu Shiage",
    "Code": "'006'",
    "EnglishName": "Information",
    "JapaneseName": "連絡事項",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Gaibu Shiage",
    "Code": "'001'",
    "EnglishName": "CorrectionRequest",
    "JapaneseName": "確認依頼",
    "NeedFollowUp": "YES",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Gaibu Shiage",
    "Code": "'002'",
    "EnglishName": "Tsuika Henko Shinsei",
    "JapaneseName": "追変提出依頼",
    "NeedFollowUp": "YES",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Gaibu Shiage",
    "Code": "'004'",
    "EnglishName": "CorrectionRequest GC",
    "JapaneseName": "GC是正依頼",
    "NeedFollowUp": "YES",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Gaibu Shiage",
    "Code": "'008'",
    "EnglishName": "Plan Checking",
    "JapaneseName": "図面チェック",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Hac Support",
    "Code": "'005'",
    "EnglishName": "Verification",
    "JapaneseName": "確認事項",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Hac Support",
    "Code": "'006'",
    "EnglishName": "Information",
    "JapaneseName": "連絡事項",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Hac Support",
    "Code": "'001'",
    "EnglishName": "CorrectionRequest",
    "JapaneseName": "確認依頼",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Hac Support",
    "Code": "'002'",
    "EnglishName": "Tsuika Henko Shinsei",
    "JapaneseName": "追変提出依頼",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Hac Support",
    "Code": "'003'",
    "EnglishName": "Request for TH",
    "JapaneseName": "追加変更申請",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Hac Support",
    "Code": "'008'",
    "EnglishName": "Plan Checking",
    "JapaneseName": "図面チェック",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Hac Support",
    "Code": "'007'",
    "EnglishName": "Plan Sending",
    "JapaneseName": "資料送付",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Hac Support",
    "Code": "'004'",
    "EnglishName": "CorrectionRequest GC",
    "JapaneseName": "GC是正依頼",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Hac Support",
    "Code": "'014'",
    "EnglishName": "GC BOSS Correction",
    "JapaneseName": "GC確認依頼",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Kensetsu",
    "Code": "'005'",
    "EnglishName": "Verification",
    "JapaneseName": "確認事項",
    "NeedFollowUp": "YES",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Kensetsu",
    "Code": "'006'",
    "EnglishName": "Information",
    "JapaneseName": "連絡事項",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Kensetsu",
    "Code": "'001'",
    "EnglishName": "CorrectionRequest",
    "JapaneseName": "確認依頼",
    "NeedFollowUp": "YES",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Kensetsu",
    "Code": "'002'",
    "EnglishName": "Tsuika Henko Shinsei",
    "JapaneseName": "追変提出依頼",
    "NeedFollowUp": "YES",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Kensetsu",
    "Code": "'003'",
    "EnglishName": "Request for TH",
    "JapaneseName": "追加変更申請",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Kensetsu",
    "Code": "'008'",
    "EnglishName": "Plan Checking",
    "JapaneseName": "図面チェック",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Kensetsu",
    "Code": "'007'",
    "EnglishName": "Plan Sending",
    "JapaneseName": "資料送付",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Kensetsu",
    "Code": "'014'",
    "EnglishName": "GC BOSS Correction",
    "JapaneseName": "GC確認依頼",
    "NeedFollowUp": "YES",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Setsubi Hiroi",
    "Code": "'001'",
    "EnglishName": "CorrectionRequest",
    "JapaneseName": "確認依頼",
    "NeedFollowUp": "YES",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Setsubi Hiroi",
    "Code": "'005'",
    "EnglishName": "Verification",
    "JapaneseName": "確認事項",
    "NeedFollowUp": "YES",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Setsubi Hiroi",
    "Code": "'006'",
    "EnglishName": "Information",
    "JapaneseName": "連絡事項",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Setsubi Hiroi",
    "Code": "'014'",
    "EnglishName": "GC BOSS Correction",
    "JapaneseName": "GC確認依頼",
    "NeedFollowUp": "YES",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Setsubi Hiroi",
    "Code": "'002'",
    "EnglishName": "Tsuika Henko Shinsei",
    "JapaneseName": "追変提出依頼",
    "NeedFollowUp": "YES",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Setsubi Hiroi",
    "Code": "'003'",
    "EnglishName": "Request for TH",
    "JapaneseName": "追加変更申請",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Shitaji Hiroi",
    "Code": "'010'",
    "EnglishName": "Ashiba Plan sending",
    "JapaneseName": "足場資料送付",
    "NeedFollowUp": "YES",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Shitaji Hiroi",
    "Code": "'005'",
    "EnglishName": "Verification",
    "JapaneseName": "確認事項",
    "NeedFollowUp": "YES",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Shitaji Hiroi",
    "Code": "'003'",
    "EnglishName": "Request for TH",
    "JapaneseName": "追加変更申請",
    "NeedFollowUp": "YES",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Shitaji Hiroi",
    "Code": "'006'",
    "EnglishName": "Information",
    "JapaneseName": "連絡事項",
    "NeedFollowUp": "YES",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Shitaji Hiroi",
    "Code": "'001'",
    "EnglishName": "CorrectionRequest",
    "JapaneseName": "確認依頼",
    "NeedFollowUp": "YES",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Shitaji Hiroi",
    "Code": "'002'",
    "EnglishName": "Tsuika Henko Shinsei",
    "JapaneseName": "追変提出依頼",
    "NeedFollowUp": "YES",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Shitaji Hiroi",
    "Code": "'008'",
    "EnglishName": "Plan Checking",
    "JapaneseName": "図面チェック",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Shitaji Hiroi",
    "Code": "'004'",
    "EnglishName": "CorrectionRequest GC",
    "JapaneseName": "GC是正依頼",
    "NeedFollowUp": "YES",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Shitaji Hiroi",
    "Code": "'007'",
    "EnglishName": "Plan Sending",
    "JapaneseName": "資料送付",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Shitaji Hiroi",
    "Code": "'014'",
    "EnglishName": "GC BOSS Correction",
    "JapaneseName": "GC確認依頼",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Yusou",
    "Code": "'010'",
    "EnglishName": "Ashiba Plan sending",
    "JapaneseName": "足場資料送付",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Yusou",
    "Code": "'003'",
    "EnglishName": "Request for TH",
    "JapaneseName": "追加変更申請",
    "NeedFollowUp": "YES",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Yusou",
    "Code": "'005'",
    "EnglishName": "Verification",
    "JapaneseName": "確認事項",
    "NeedFollowUp": "YES",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Yusou",
    "Code": "'001'",
    "EnglishName": "CorrectionRequest",
    "JapaneseName": "確認依頼",
    "NeedFollowUp": "YES",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Yusou",
    "Code": "'006'",
    "EnglishName": "Information",
    "JapaneseName": "連絡事項",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Yusou",
    "Code": "'002'",
    "EnglishName": "Tsuika Henko Shinsei",
    "JapaneseName": "追変提出依頼",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Yusou",
    "Code": "'004'",
    "EnglishName": "CorrectionRequest GC",
    "JapaneseName": "GC是正依頼",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Yusou",
    "Code": "'008'",
    "EnglishName": "Plan Checking",
    "JapaneseName": "図面チェック",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Yusou",
    "Code": "'007'",
    "EnglishName": "Plan Sending",
    "JapaneseName": "資料送付",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Yusou",
    "Code": "'014'",
    "EnglishName": "GC BOSS Correction",
    "JapaneseName": "GC確認依頼",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Zousaku Hiroi",
    "Code": "'005'",
    "EnglishName": "Verification",
    "JapaneseName": "確認事項",
    "NeedFollowUp": "YES",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Zousaku Hiroi",
    "Code": "'006'",
    "EnglishName": "Information",
    "JapaneseName": "連絡事項",
    "NeedFollowUp": "YES",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Zousaku Hiroi",
    "Code": "'010'",
    "EnglishName": "Ashiba Plan sending",
    "JapaneseName": "足場資料送付",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Zousaku Hiroi",
    "Code": "'002'",
    "EnglishName": "Tsuika Henko Shinsei",
    "JapaneseName": "追変提出依頼",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Zousaku Hiroi",
    "Code": "'001'",
    "EnglishName": "CorrectionRequest",
    "JapaneseName": "確認依頼",
    "NeedFollowUp": "YES",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Zousaku Hiroi",
    "Code": "'003'",
    "EnglishName": "Request for TH",
    "JapaneseName": "追加変更申請",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Zousaku Hiroi",
    "Code": "'008'",
    "EnglishName": "Plan Checking",
    "JapaneseName": "図面チェック",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Zousaku Hiroi",
    "Code": "'004'",
    "EnglishName": "CorrectionRequest GC",
    "JapaneseName": "GC是正依頼",
    "NeedFollowUp": "YES",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Zousaku Hiroi",
    "Code": "'007'",
    "EnglishName": "Plan Sending",
    "JapaneseName": "資料送付",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "HACCHU",
    "Section": "Zousaku Hiroi",
    "Code": "'014'",
    "EnglishName": "GC BOSS Correction",
    "JapaneseName": "GC確認依頼",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Panel Assembly",
    "Section": "Panel",
    "Code": "'001'",
    "EnglishName": "CorrectionRequest",
    "JapaneseName": "確認依頼",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "Panel Assembly",
    "Section": "Panel",
    "Code": "'003'",
    "EnglishName": "Request for TH",
    "JapaneseName": "追加変更申請",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Panel Assembly",
    "Section": "Panel",
    "Code": "'005'",
    "EnglishName": "Verification",
    "JapaneseName": "確認事項",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "Panel Assembly",
    "Section": "Yukadanbou A",
    "Code": "'005'",
    "EnglishName": "Verification",
    "JapaneseName": "確認事項",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "Panel Assembly",
    "Section": "Yukadanbou A",
    "Code": "'001'",
    "EnglishName": "CorrectionRequest",
    "JapaneseName": "確認依頼",
    "NeedFollowUp": "",
    "FIELD7": "NOT IN USE"
  },
  {
    "Department": "Panel Assembly",
    "Section": "Yukadanbou A",
    "Code": "'003'",
    "EnglishName": "Request for TH",
    "JapaneseName": "追加変更申請",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Panel Completion",
    "Section": "Duct Wiring",
    "Code": "'001'",
    "EnglishName": "CorrectionRequest",
    "JapaneseName": "確認依頼",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "Panel Completion",
    "Section": "Insulation",
    "Code": "'001'",
    "EnglishName": "CorrectionRequest",
    "JapaneseName": "確認依頼",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "Plan Application",
    "Section": "Application Document Jikugumi",
    "Code": "'005'",
    "EnglishName": "Verification",
    "JapaneseName": "確認事項",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Plan Application",
    "Section": "Application Document Jikugumi",
    "Code": "'014'",
    "EnglishName": "GC BOSS Correction",
    "JapaneseName": "GC確認依頼",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "Plan Application",
    "Section": "Application Document Jikugumi",
    "Code": "'006'",
    "EnglishName": "Information",
    "JapaneseName": "連絡事項",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Plan Application",
    "Section": "Application Document Jikugumi",
    "Code": "'003'",
    "EnglishName": "Request for TH",
    "JapaneseName": "追加変更申請",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "Plan Application",
    "Section": "Application Document Jikugumi",
    "Code": "'001'",
    "EnglishName": "CorrectionRequest",
    "JapaneseName": "確認依頼",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "Plan Application",
    "Section": "Application Document Wakugumi",
    "Code": "'006'",
    "EnglishName": "Information",
    "JapaneseName": "連絡事項",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "Plan Application",
    "Section": "Application Document Wakugumi",
    "Code": "'005'",
    "EnglishName": "Verification",
    "JapaneseName": "確認事項",
    "NeedFollowUp": "",
    "FIELD7": "NOT IN USE"
  },
  {
    "Department": "Plan Application",
    "Section": "Application Document Wakugumi",
    "Code": "'014'",
    "EnglishName": "GC BOSS Correction",
    "JapaneseName": "GC確認依頼",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "Plan Application",
    "Section": "Application Document Wakugumi",
    "Code": "'003'",
    "EnglishName": "Request for TH",
    "JapaneseName": "追加変更申請",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "Plan Application",
    "Section": "Application Document Wakugumi",
    "Code": "'001'",
    "EnglishName": "CorrectionRequest",
    "JapaneseName": "確認依頼",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "Plan Application",
    "Section": "Application Investigation",
    "Code": "'005'",
    "EnglishName": "Verification",
    "JapaneseName": "確認事項",
    "NeedFollowUp": "",
    "FIELD7": "NOT IN USE"
  },
  {
    "Department": "Plan Application",
    "Section": "Application Investigation",
    "Code": "'001'",
    "EnglishName": "CorrectionRequest",
    "JapaneseName": "確認依頼",
    "NeedFollowUp": "",
    "FIELD7": "NOT IN USE"
  },
  {
    "Department": "Plan Application",
    "Section": "Application Investigation",
    "Code": "'014'",
    "EnglishName": "GC BOSS Correction",
    "JapaneseName": "GC確認依頼",
    "NeedFollowUp": "",
    "FIELD7": "NOT IN USE"
  },
  {
    "Department": "Plan Application",
    "Section": "Application Investigation",
    "Code": "'006'",
    "EnglishName": "Information",
    "JapaneseName": "連絡事項",
    "NeedFollowUp": "",
    "FIELD7": "NOT IN USE"
  },
  {
    "Department": "Plan Application",
    "Section": "Application Investigation",
    "Code": "'003'",
    "EnglishName": "Request for TH",
    "JapaneseName": "追加変更申請",
    "NeedFollowUp": "",
    "FIELD7": "NOT IN USE"
  },
  {
    "Department": "Plan Application",
    "Section": "Plan Documentation",
    "Code": "'005'",
    "EnglishName": "Verification",
    "JapaneseName": "確認事項",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "Plan Application",
    "Section": "Plan Documentation",
    "Code": "'014'",
    "EnglishName": "GC BOSS Correction",
    "JapaneseName": "GC確認依頼",
    "NeedFollowUp": "",
    "FIELD7": "NOT IN USE"
  },
  {
    "Department": "Plan Application",
    "Section": "Plan Documentation",
    "Code": "'006'",
    "EnglishName": "Information",
    "JapaneseName": "連絡事項",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Plan Application",
    "Section": "Plan Documentation",
    "Code": "'003'",
    "EnglishName": "Request for TH",
    "JapaneseName": "追加変更申請",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "Plan Application",
    "Section": "Plan Documentation",
    "Code": "'001'",
    "EnglishName": "CorrectionRequest",
    "JapaneseName": "確認依頼",
    "NeedFollowUp": "",
    "FIELD7": "NOT IN USE"
  },
  {
    "Department": "Plan Application",
    "Section": "Plan Management",
    "Code": "'005'",
    "EnglishName": "Verification",
    "JapaneseName": "確認事項",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "Plan Application",
    "Section": "Plan Management",
    "Code": "'014'",
    "EnglishName": "GC BOSS Correction",
    "JapaneseName": "GC確認依頼",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "Plan Application",
    "Section": "Plan Management",
    "Code": "'006'",
    "EnglishName": "Information",
    "JapaneseName": "連絡事項",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Plan Application",
    "Section": "Plan Management",
    "Code": "'003'",
    "EnglishName": "Request for TH",
    "JapaneseName": "追加変更申請",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Plan Application",
    "Section": "Plan Management",
    "Code": "'001'",
    "EnglishName": "CorrectionRequest",
    "JapaneseName": "確認依頼",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "Structural Panel",
    "Section": "QC",
    "Code": "'004'",
    "EnglishName": "CorrectionRequest GC",
    "JapaneseName": "GC是正依頼",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Structural Panel",
    "Section": "QC",
    "Code": "'001'",
    "EnglishName": "CorrectionRequest",
    "JapaneseName": "確認依頼",
    "NeedFollowUp": "YES",
    "FIELD7": ""
  },
  {
    "Department": "Structural Panel",
    "Section": "QC",
    "Code": "'003'",
    "EnglishName": "Request for TH",
    "JapaneseName": "追加変更申請",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Structural Planning",
    "Section": "Henkou & Support",
    "Code": "'020'",
    "EnglishName": "Cancel Plan",
    "JapaneseName": "Cancel Plan",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Structural Planning",
    "Section": "Henkou & Support",
    "Code": "'004'",
    "EnglishName": "CorrectionRequest GC",
    "JapaneseName": "GC是正依頼",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Structural Planning",
    "Section": "Henkou & Support",
    "Code": "'001'",
    "EnglishName": "CorrectionRequest",
    "JapaneseName": "確認依頼",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Structural Planning",
    "Section": "Henkou & Support",
    "Code": "'003'",
    "EnglishName": "Request for TH",
    "JapaneseName": "追加変更申請",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "Structural Planning",
    "Section": "Henkou & Support",
    "Code": "'007'",
    "EnglishName": "Plan Sending",
    "JapaneseName": "資料送付",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Structural Planning",
    "Section": "Henkou & Support",
    "Code": "'015'",
    "EnglishName": "SC1CorrectionReq.",
    "JapaneseName": "確認依頼",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "Structural Planning",
    "Section": "Structural Design",
    "Code": "'004'",
    "EnglishName": "CorrectionRequest GC",
    "JapaneseName": "GC是正依頼",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Structural Planning",
    "Section": "Structural Design",
    "Code": "'001'",
    "EnglishName": "CorrectionRequest",
    "JapaneseName": "確認依頼",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Structural Planning",
    "Section": "Structural Design",
    "Code": "'020'",
    "EnglishName": "Cancel Plan",
    "JapaneseName": "Cancel Plan",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Structural Planning",
    "Section": "Structural Design",
    "Code": "'007'",
    "EnglishName": "Plan Sending",
    "JapaneseName": "資料送付",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Structural Planning",
    "Section": "Structural Design",
    "Code": "'015'",
    "EnglishName": "SC1CorrectionReq.",
    "JapaneseName": "確認依頼",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "Structural Planning",
    "Section": "Structural Design",
    "Code": "'003'",
    "EnglishName": "Request for TH",
    "JapaneseName": "追加変更申請",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "Structural Planning",
    "Section": "Structural Investigation 1",
    "Code": "'001'",
    "EnglishName": "CorrectionRequest",
    "JapaneseName": "確認依頼",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Structural Planning",
    "Section": "Structural Investigation 1",
    "Code": "'020'",
    "EnglishName": "Cancel Plan",
    "JapaneseName": "Cancel Plan",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Structural Planning",
    "Section": "Structural Investigation 1",
    "Code": "'004'",
    "EnglishName": "CorrectionRequest GC",
    "JapaneseName": "GC是正依頼",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Structural Planning",
    "Section": "Structural Investigation 1",
    "Code": "'007'",
    "EnglishName": "Plan Sending",
    "JapaneseName": "資料送付",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Structural Planning",
    "Section": "Structural Investigation 1",
    "Code": "'015'",
    "EnglishName": "SC1CorrectionReq.",
    "JapaneseName": "確認依頼",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "Structural Planning",
    "Section": "Structural Investigation 1",
    "Code": "'003'",
    "EnglishName": "Request for TH",
    "JapaneseName": "追加変更申請",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "Structural Planning",
    "Section": "Structural Investigation 2",
    "Code": "'020'",
    "EnglishName": "Cancel Plan",
    "JapaneseName": "Cancel Plan",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Structural Planning",
    "Section": "Structural Investigation 2",
    "Code": "'004'",
    "EnglishName": "CorrectionRequest GC",
    "JapaneseName": "GC是正依頼",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Structural Planning",
    "Section": "Structural Investigation 2",
    "Code": "'001'",
    "EnglishName": "CorrectionRequest",
    "JapaneseName": "確認依頼",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Structural Planning",
    "Section": "Structural Investigation 2",
    "Code": "'003'",
    "EnglishName": "Request for TH",
    "JapaneseName": "追加変更申請",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "Structural Planning",
    "Section": "Structural Investigation 2",
    "Code": "'007'",
    "EnglishName": "Plan Sending",
    "JapaneseName": "資料送付",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "Structural Planning",
    "Section": "Structural Investigation 2",
    "Code": "'015'",
    "EnglishName": "SC1CorrectionReq.",
    "JapaneseName": "確認依頼",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "SUIDOU",
    "Section": "Haikan",
    "Code": "'006'",
    "EnglishName": "Information",
    "JapaneseName": "連絡事項",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "SUIDOU",
    "Section": "Haikan",
    "Code": "'008'",
    "EnglishName": "Plan Checking",
    "JapaneseName": "図面チェック",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "SUIDOU",
    "Section": "Haikan",
    "Code": "'005'",
    "EnglishName": "Verification",
    "JapaneseName": "確認事項",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "SUIDOU",
    "Section": "Haikan",
    "Code": "'003'",
    "EnglishName": "Request for TH",
    "JapaneseName": "追加変更申請",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "SUIDOU",
    "Section": "Haikan",
    "Code": "'001'",
    "EnglishName": "CorrectionRequest",
    "JapaneseName": "確認依頼",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "SUIDOU",
    "Section": "Piping Estimation",
    "Code": "'008'",
    "EnglishName": "Plan Checking",
    "JapaneseName": "図面チェック",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "SUIDOU",
    "Section": "Piping Estimation",
    "Code": "'006'",
    "EnglishName": "Information",
    "JapaneseName": "連絡事項",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "SUIDOU",
    "Section": "Piping Estimation",
    "Code": "'005'",
    "EnglishName": "Verification",
    "JapaneseName": "確認事項",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "SUIDOU",
    "Section": "Piping Estimation",
    "Code": "'003'",
    "EnglishName": "Request for TH",
    "JapaneseName": "追加変更申請",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "SUIDOU",
    "Section": "Piping Estimation",
    "Code": "'001'",
    "EnglishName": "CorrectionRequest",
    "JapaneseName": "確認依頼",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "SUIDOU",
    "Section": "Piping Sending",
    "Code": "'006'",
    "EnglishName": "Information",
    "JapaneseName": "連絡事項",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "SUIDOU",
    "Section": "Piping Sending",
    "Code": "'008'",
    "EnglishName": "Plan Checking",
    "JapaneseName": "図面チェック",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "SUIDOU",
    "Section": "Piping Sending",
    "Code": "'003'",
    "EnglishName": "Request for TH",
    "JapaneseName": "追加変更申請",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "SUIDOU",
    "Section": "Piping Sending",
    "Code": "'005'",
    "EnglishName": "Verification",
    "JapaneseName": "確認事項",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "SUIDOU",
    "Section": "Piping Sending",
    "Code": "'001'",
    "EnglishName": "CorrectionRequest",
    "JapaneseName": "確認依頼",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "UNIT WIRING",
    "Section": "Sales and Implementation",
    "Code": "'001'",
    "EnglishName": "CorrectionRequest",
    "JapaneseName": "確認依頼",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "UNIT WIRING",
    "Section": "Sales and Implementation",
    "Code": "'004'",
    "EnglishName": "CorrectionRequest GC",
    "JapaneseName": "GC是正依頼",
    "NeedFollowUp": "YES ",
    "FIELD7": ""
  },
  {
    "Department": "UNIT WIRING",
    "Section": "Sales and Implementation",
    "Code": "'003'",
    "EnglishName": "Request for TH",
    "JapaneseName": "追加変更申請",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  },
  {
    "Department": "UNIT WIRING",
    "Section": "Sales and Implementation",
    "Code": "'007'",
    "EnglishName": "Plan Sending",
    "JapaneseName": "資料送付",
    "NeedFollowUp": "NO",
    "FIELD7": ""
  }
 ]
  export default arrayOfNeedFollowUp;
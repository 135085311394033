import axios from "axios";
import keys from "../../alert";

const baseUrl = keys.auth.BaseUrl;
const apikey = keys.auth.apiKey;

const httpRequest = {
  //*************************************//
  //           GET JOB KIND              //
  //*************************************//
  Get() {
    return Promise.resolve(axios.get(`${baseUrl}/jobkind`, apikey));
  },
};

export default httpRequest;

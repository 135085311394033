<template>
  <div>
    <v-menu
      v-model="menu"
      transition="slide-x-transition"
      offset-y
      :nudge-width="1300"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <span style="color: #666666; font-weight: bold" dark v-bind="attrs" v-on="on">
          <v-icon color="red">mdi-magnify</v-icon> Confirmation
        </span>
      </template>
      <!-- USER INFORMATION -->
      <v-card class="pa-2">
        <v-row class="ma-n1 mr-10">
          <v-spacer></v-spacer>
          <v-icon @click="menu = !menu" style="cursor: pointer">mdi-close-thick</v-icon>
        </v-row>
        <v-row>
          <!-- FOR FILTER -->
          <v-col class="mr-n4" cols="3">
            <v-card class="pa-2" height="500px">
              <v-card-subtitle>Filter</v-card-subtitle>
              <v-divider class="mb-3 mt-n2" />
              <!-- DUE -->
              <div class="pretty p-switch p-fill mb-2">
                <input v-model="filter.due" type="checkbox" />
                <div class="state p-danger">
                  <label style="color: grey">Due</label>
                </div>
              </div>

              <!-- CUSTOMER CODE -->
              <v-text-field
                v-model="filter.customerCode"
                color="red"
                label="Customer Code"
                filled
                outlined
                clearable
                dense
              />

              <!-- TYPE -->
              <!-- <v-select
                v-model="filter.userType"
                :items="userType"
                color="red"
                label="Type"
                filled
                outlined
                dense
              /> -->

              <!-- JOB KIND -->
              <v-autocomplete
                v-model="filter.jobkinds"
                :items="jobkinds"
                item-value="EnglishName"
                item-text="itemValue"
                color="red"
                label="Job Kind"
                filled
                outlined
                dense
              />

              <!-- DATE FROM -->
              <v-menu
                class="text-center"
                content-class="elevation-0"
                :close-oncontent-click="false"
                transition="scale-transition"
                offet-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="DateFrom"
                    v-bind="attrs"
                    v-on="on"
                    color="red"
                    label="Date From"
                    filled
                    outlined
                    dense
                  />
                </template>
                <v-date-picker v-model="DateFrom" color="#df6464" />
              </v-menu>

              <!-- DATE TO -->
              <v-menu
                class="text-center"
                content-class="elevation-0"
                :close-oncontent-click="false"
                transition="scale-transition"
                offet-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="DateTo"
                    v-bind="attrs"
                    v-on="on"
                    color="red"
                    label="Date To"
                    filled
                    outlined
                    dense
                  />
                </template>
                <v-date-picker v-model="DateTo" color="#df6464" />
              </v-menu>
              <v-btn color="primary" block dense @click="clickSearchFollowUp()"
                ><v-icon>mdi-magnify</v-icon>
                Search
              </v-btn>
              <v-btn class="mt-5" color="success" block dense @click="clearSeach()"
                ><v-icon>mdi-refresh</v-icon>
                Refresh filter
              </v-btn>
            </v-card>
          </v-col>
          <!-- FOR RECORD -->
          <v-col>
            <v-card>
              <v-simple-table fixed-header height="500">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th
                        class="text-left"
                        v-for="val in headers"
                        :key="val"
                        v-text="val"
                      ></th>
                    </tr>
                  </thead>
                  <tbody v-if="inquiryRecord">
                    <tr v-for="(val, i) in inquiryRecord" :key="i">
                      <!-- :style="val.Due <= globalDueDate ? 'background-color:#F88379' : ''" -->
                      <td style="width: 500px" v-text="val.id" />
                      <td style="width: 500px" v-text="val.CustomerCode" />
                      <td style="width: 500px" v-text="val.JobKind" />
                      <td style="width: 500px" v-text="val.InquiryKind" />
                      <td style="width: 500px" v-text="val.SenderName" />
                      <td style="width: 500px" v-text="val.Subject" />
                      <td style="width: 500px" v-text="val.Send" />
                      <td style="width: 500px" v-text="val.Due" />
                      <td style="width: 500px">
                        <span v-if="!val.Attachment" style="color: red"
                          >No attachment</span
                        >
                        <span v-else>
                          <span
                            outlined
                            dense
                            color="red"
                            @click="downloadPdf(val.Path, val.Attachment)"
                            style="color: #1976d2; cursor: pointer; font-size: 13px"
                            >{{ val.Attachment.split("_")[1] }} </span
                          ><br />
                        </span>
                      </td>
                      <td>
                        <v-btn
                          v-if="
                            val.Sendername === userRecord.FullName &&
                            val.FollowupStatus != 3
                          "
                          @click="
                            PassDataToFollowup(val.HandleEmail, userEmail),
                              (confirmationRecord = val)
                          "
                          color="error"
                          small
                          outlined
                        >
                          <v-icon small>mdi-alert-circle-outline</v-icon>
                          Send
                        </v-btn>
                        <span v-else style="color: red; font-weight: bold">
                          {{ val.FollowupStatus == 3 ? "Already send 3 followup" : "" }}
                        </span>
                      </td>
                    </tr>
                    <v-card v-intersect="infiniteScrolling"></v-card>
                    <transition name="fade">
                      <div class="loading" v-show="loading">
                        <span><v-icon>mdi-loading mdi-spin</v-icon></span> Loading
                      </div>
                    </transition>
                  </tbody>
                  <!-- FOR NO RECORD -->
                  <tbody v-else>
                    <tr>
                      <td
                        class="text-center"
                        colspan="7"
                        v-html="'No Record Found'"
                        style="color: red; font-weight: bold; font-size: 20px"
                      />
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
      <!-- COMPONENTS -->
      <OverDueComponent
        ref="overdue"
        @closeDialog="CloseMenu()"
        :ciInfo="confirmationRecord"
        :userRecord="userRecord"
      />
    </v-menu>
  </div>
</template>
<script>
import store from "@/store";
import moment from "moment";
// import InquiryStatusTable from "../../functions/httpRequest/ExternalConnection/InquiryStatusTable";
import OverDueComponent from "./OverdueFollowup.vue";
import awsFunction from "../../functions/Attachment";
import axios from "axios";
import objects from "../../functions/alert";
import keys from "../../functions/alert";

export default {
  data() {
    return {
      globalDueDate: moment().format("YYYY-MM-DD"),
      page: 10,
      loading: false,
      menu: false,
      userRecord: store.state.userinfo,
      confirmationRecord: {},
      filter: {},
      inquiryRecord: [],
      headers: [
        "ID",
        "Customer Code",
        "Job Kind",
        "Inquiry Kind",
        "Sender Name",
        "Subject",
        "Send",
        "Due",
        "Attachment",
        "Followup",
      ],
      userType: ["All", "User"],
      DateFrom: moment().subtract(1, "M").format("YYYY-MM-DD"),
      DateTo: moment().format("YYYY-MM-DD"),
    };
  },
  components: {
    OverDueComponent: OverDueComponent,
  },
  props: { jobkinds: { type: Array }, userEmail: { type: String } },
  created() {
    // this.$set(this.filter, "userType", "User");
    // this.$set(this.filter, "DateTo", this.dateToday);
    // this.$set(
    //   this.filter,
    //   "DateFrom",
    //   moment(this.dateToday).add(-2, "M").format("YYYY-MM-DD")
    // );
  },
  watch: {
    menu(newval) {
      if (newval) this.InquiryStatus();
    },
  },
  computed: {
    // FilteredInquiryRecord() {
    //   let data = this.inquiryRecord;
    //   // FOR CUSTOMER CODE
    //   if (this.filter.customerCode)
    //     data = data.filter((code) =>
    //       code.CustomerCode.includes(this.filter.customerCode)
    //     );
    //   // FOR TYPE OF USER
    //   if (this.filter.userType)
    //     data = data.filter((user) =>
    //       this.filter.userType == "User"
    //         ? user.Sendername == this.userRecord.FullName
    //         : user
    //     );
    //   // FOR JOBKIND
    //   if (this.filter.jobkinds)
    //     data = data.filter((job) => job.JobKind === this.filter.jobkinds);
    //  FOR DATEFROM AND DATETO
    // if (this.filter.DateTo && this.filter.DateFrom)
    //   data = data.filter((date) => {
    //     const dateFilter = this.filter.due ? date.Due : date.Send;
    //     const ParseDate = (date) => parseInt(moment(date).format("YYYYMMDD"));
    //     return (
    //       ParseDate(dateFilter) >= ParseDate(this.filter.DateFrom) &&
    //       ParseDate(dateFilter) <= ParseDate(this.filter.DateTo)
    //     );
    //   });
    // return data;
    // },
  },

  methods: {
    clearSeach() {
      this.InquiryStatus();
      this.filter.customerCode = "";
      this.filter.jobkinds = "";
      this.filter.due = false;
    },
    clickSearchFollowUp() {
      if (this.filter.due == true) {
        this.InquiryStatus();
        return false;
      } else {
        this.InquiryStatus();
      }

      if (this.filter.customerCode && this.filter.jobkinds) {
        this.searchFollowUp();
        return false;
      } else {
        this.$toast.error("Complete the details", objects.alert);
      }
    },

    searchFollowUp() {
      let customerCode = this.filter.customerCode;
      // let type = this.filter.userType;
      let DateFrom = this.DateFrom;
      let DateTo = this.DateTo;
      let jobKind = this.filter.jobkinds;

      axios
        .get(
          `${keys.auth.BaseUrl}/searchSpecificInquiryStatus/${store.state.userinfo.FullName}/${customerCode}/${jobKind}/${DateFrom}/${DateTo}`,
          keys.auth.apiKey
        )
        .then((res) => {
          console.log(res.data);
          if (res.data.length > 0) {
            // console.log(res.data);
            let recordSearch = [];
            if (res.data.length)
              for (let val of res.data)
                recordSearch.push({
                  ...val,
                  CustomerCode: val.CustomerCode,
                  JobKind: val.JobKind,
                  InquiryKind: val.InquiryKind,
                  Subject: val.Subject,
                  Send: moment(val.ReceivedDate).format("YYYY-MM-DD"),
                  Due: moment(val.DueDate).format("YYYY-MM-DD"),
                  Sendername: val.SenderName,
                  HandleEmail: val.HandleEmail,
                });

            this.inquiryRecord = recordSearch;
          } else {
            this.$toast.error("No control code found on your inquiry", objects.alert);
          }
        });
    },
    splitAttachment(attachment) {
      let splitAttachment = attachment.split(",");

      const splitDate = [];
      for (let x in splitAttachment) {
        splitDate.push({
          OriginalName: splitAttachment[x],
          ClientName: splitAttachment[x].split("_")[1],
        });
      }
      return splitDate;
    },
    async downloadPdf(valPath, valAttachment) {
      // const PathAttach = valPath+"/"+valAttachment
      awsFunction.download(valPath, valAttachment);
      //   const pathViewer = await awsFunction.convertToFileType([`${Path}/${Attachment}`]);
      //   // console.log(pathViewer)
      //  window.URL.createObjectURL(pathViewer)
    },
    PassDataToFollowup(handleBy, Email) {
      const obj = {
        RecipientEmail: JSON.parse(handleBy),
        SenderEmail: Email,
      };
      this.$refs.overdue.GetData(obj);
    },
    InquiryStatus() {
      console.log(this.filter.due);
      axios
        .get(
          `${keys.auth.BaseUrl}/getSpecificInquiryStatus/0/${this.page}/${store.state.userinfo.FullName}/${this.DateFrom}/${this.DateTo}`,
          keys.auth.apiKey
        )
        .then((res) => {
          if (this.filter.due == true) {
            let Duerecord = [];
            if (res.data.length)
              for (let val of res.data)
                if (this.globalDueDate >= val.DueDate)
                  Duerecord.push({
                    ...val,
                    CustomerCode: val.CustomerCode,
                    JobKind: val.JobKind,
                    InquiryKind: val.InquiryKind,
                    Subject: val.Subject,
                    Send: moment(val.ReceivedDate).format("YYYY-MM-DD"),
                    Due: moment(val.DueDate).format("YYYY-MM-DD"),
                    Sendername: val.SenderName,
                    HandleEmail: val.HandleEmail,
                  });

            this.inquiryRecord = Duerecord;
          } else {
            // console.log(res.data);
            let record = [];
            if (res.data.length)
              for (let val of res.data)
                record.push({
                  ...val,
                  CustomerCode: val.CustomerCode,
                  JobKind: val.JobKind,
                  InquiryKind: val.InquiryKind,
                  Subject: val.Subject,
                  Send: moment(val.ReceivedDate).format("YYYY-MM-DD"),
                  Due: moment(val.DueDate).format("YYYY-MM-DD"),
                  Sendername: val.SenderName,
                  HandleEmail: val.HandleEmail,
                });

            this.inquiryRecord = record;
          }
        });
    },
    infiniteScrolling() {
      if (this.filter.customerCode && this.filter.jobkinds) {
        this.searchFollowUp;
        return false;
      }
      this.loading = true;
      // let page = 10;
      this.page++;
      setTimeout(() => {
        axios
          .get(
            `${keys.auth.BaseUrl}/getSpecificInquiryStatus/0/${this.page}/${store.state.userinfo.FullName}/${this.DateFrom}/${this.DateTo}`,
            keys.auth.apiKey
          )

          .then((res) => {
            if (this.filter.due == true) {
              let Duerecord = [];
              if (res.data.length)
                for (let val of res.data)
                  if (this.globalDueDate >= val.DueDate)
                    Duerecord.push({
                      ...val,
                      CustomerCode: val.CustomerCode,
                      JobKind: val.JobKind,
                      InquiryKind: val.InquiryKind,
                      Subject: val.Subject,
                      Send: moment(val.ReceivedDate).format("YYYY-MM-DD"),
                      Due: moment(val.DueDate).format("YYYY-MM-DD"),
                      Sendername: val.SenderName,
                      HandleEmail: val.HandleEmail,
                    });

              this.inquiryRecord = Duerecord;
            } else {
              // console.log(res.data);
              let record = [];
              if (res.data.length)
                for (let val of res.data)
                  record.push({
                    ...val,
                    CustomerCode: val.CustomerCode,
                    JobKind: val.JobKind,
                    InquiryKind: val.InquiryKind,
                    Subject: val.Subject,
                    Send: moment(val.ReceivedDate).format("YYYY-MM-DD"),
                    Due: moment(val.DueDate).format("YYYY-MM-DD"),
                    Sendername: val.SenderName,
                    HandleEmail: val.HandleEmail,
                  });

              this.inquiryRecord = record;
            }
            this.loading = false;
          });
      }, 2000);
    },
    CloseMenu() {
      this.menu = false;
    },
  },
};
</script>

<style>
.loading {
  text-align: center;
  position: absolute;
  color: #fff;
  z-index: 1;
  background: #df6464;
  padding: 8px 18px;
  border-radius: 5px;
  left: calc(50% - 45px);
  top: calc(50% - 18px);
}
</style>
